import React from "react";
import {ReadSubscriptions} from "../models/Subscriptions";

interface ModalObserversInComponentProps {
    subscriptionsRead: ReadSubscriptions[]

    onClickDeleteUser: (idUser: number, indexOfList: number) => void
    onClickConfirmUser: (idUser: number, indexOfList: number) => void

    indexOfList: number
    isLoadingDelete: boolean
    isLoadingDeleteError: string
    isLoadingConfirm: boolean
    isLoadingConfirmError: string
}

export function ModalObserversInComponent({subscriptionsRead, onClickDeleteUser, onClickConfirmUser, indexOfList, isLoadingDelete, isLoadingDeleteError, isLoadingConfirm, isLoadingConfirmError}: ModalObserversInComponentProps) {
    let textButtonDelete = "Отменить"
    let textButtonConfirm = "Одобрить"
    if (isLoadingDeleteError) {
        textButtonDelete = "Ошибка! Еще раз"
    }
    if (isLoadingConfirmError) {
        textButtonConfirm = "Ошибка! Еще раз"
    }

    let insideHTML = []
    for (let i = 0; i < subscriptionsRead.length; i++) {
        if (indexOfList === i) {
            insideHTML.push(
                <div className="modal-pop-subscriptions-block" key={`modal-pop-subscriptions-block-${i}`}>
                    <span className="modal-pop-subscriptions-block-id" key={`modal-pop-subscriptions-block-id-${i}`}>
                    ID {subscriptionsRead[i].id_observer}
                    </span>

                    <span className="modal-pop-subscriptions-block-name" key={`modal-pop-subscriptions-block-name-${i}`}>
                        {subscriptionsRead[i].name_observer}
                    </span>
                    <div className="modal-pop-subscriptions-block-buttons" key={`modal-pop-subscriptions-block-buttons-${i}`}>
                        {isLoadingDelete &&
                            <span className="modal-pop-subscriptions-block-button-red-animation" key={`modal-pop-subscriptions-block-button-red-${i}`}
                              onClick={event => onClickDeleteUser(subscriptionsRead[i].id_observer, i)}>
                                Отменяем...
                            </span>
                        }
                        {!isLoadingDelete &&
                            <span className="modal-pop-subscriptions-block-button-red" key={`modal-pop-subscriptions-block-button-red-${i}`}
                                  onClick={event => onClickDeleteUser(subscriptionsRead[i].id_observer, i)}>
                                {textButtonDelete}
                            </span>
                        }
                        {isLoadingConfirm &&
                            <span className="modal-pop-subscriptions-block-button-blue-animation" key={`modal-pop-subscriptions-block-button-blue-${i}`}
                                  onClick={event => onClickConfirmUser(subscriptionsRead[i].id_observer, i)}>
                                Одобряем...
                            </span>
                        }
                        {!isLoadingConfirm &&
                            <span className="modal-pop-subscriptions-block-button-blue" key={`modal-pop-subscriptions-block-button-blue-${i}`}
                                  onClick={event => onClickConfirmUser(subscriptionsRead[i].id_observer, i)}>
                                {textButtonConfirm}
                            </span>
                        }
                    </div>
                </div>
            )
        } else {
            insideHTML.push(
                <div className="modal-pop-subscriptions-block" key={`modal-pop-subscriptions-block-${i}`}>
                    <span className="modal-pop-subscriptions-block-id" key={`modal-pop-subscriptions-block-id-${i}`}>
                    ID {subscriptionsRead[i].id_observer}
                    </span>

                    <span className="modal-pop-subscriptions-block-name" key={`modal-pop-subscriptions-block-name-${i}`}>
                        {subscriptionsRead[i].name_observer}
                    </span>
                    <div className="modal-pop-subscriptions-block-buttons" key={`modal-pop-subscriptions-block-buttons-${i}`}>
                        <span className="modal-pop-subscriptions-block-button-red" key={`modal-pop-subscriptions-block-button-red-${i}`}
                          onClick={event => onClickDeleteUser(subscriptionsRead[i].id_observer, i)}>
                            Отменить
                        </span>
                        <span className="modal-pop-subscriptions-block-button-blue" key={`modal-pop-subscriptions-block-button-blue-${i}`}
                              onClick={event => onClickConfirmUser(subscriptionsRead[i].id_observer, i)}>
                            Одобрить
                        </span>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="modal-pop-form">
            {insideHTML}
        </div>
    )
}