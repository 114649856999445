import React, {useState} from "react";

interface ModalDayComponentProps {
    isOwner: boolean
    comment: string
    isHasCycle: boolean

    onMenstruationWas: (menstruationWas: boolean) => void
    menstruationWasLoading: boolean
    menstruationWasLoadingError: string

    onMenstruationStartedFinished: (menstruationStarted: boolean) => void
    menstruationStartedFinishedLoading: boolean
    menstruationStartedFinishedLoadingError: string

    onMenstruationEdited: (menstruationStarted: boolean) => void
    menstruationEditedLoading: boolean
    menstruationEditedLoadingError: string

    onSaveComment: (comment: string) => void
    saveCommentLoading: boolean
    saveCommentLoadingError: string

    onDeleteCycle: () => void
    deleteCycleLoading: boolean
    deleteCycleLoadingError: string
}

export function ModalDayComponent({isOwner, comment, isHasCycle, onMenstruationWas, menstruationWasLoading, menstruationWasLoadingError, onMenstruationStartedFinished, menstruationStartedFinishedLoading, menstruationStartedFinishedLoadingError, onMenstruationEdited, menstruationEditedLoading, menstruationEditedLoadingError, onSaveComment, saveCommentLoading, saveCommentLoadingError, onDeleteCycle, deleteCycleLoading, deleteCycleLoadingError}: ModalDayComponentProps) {
    // Были ли месячные. В цикле который есть
    const [menstruationWas, setMenstruationWas] = useState(true)
    const menstruationWasHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "was") {
            setMenstruationWas(true)
        } else if (event.target.value === "wasnot") {
            setMenstruationWas(false)
        }
    }
    const setMenstruationWasHandler = async (event: React.FormEvent) => {
        event.preventDefault()

        onMenstruationWas(menstruationWas)
    }

    // Начало или конец месячных. В цикле которого нет
    const [menstruationStarted, setMenstruationStarted] = useState(true)
    const menstruationStartedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "start") {
            setMenstruationStarted(true)
        } else if (event.target.value === "finish") {
            setMenstruationStarted(false)
        }
    }
    const setMenstruationStartedFinishedHandler = async (event: React.FormEvent) => {
        event.preventDefault()

        onMenstruationStartedFinished(menstruationStarted)
    }

    // Изменение месячных
    const [menstruationEdited, setMenstruationEdited] = useState(true)
    const menstruationEditedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "start") {
            setMenstruationEdited(true)
        } else if (event.target.value === "finish") {
            setMenstruationEdited(false)
        }
    }
    const setMenstruationEditedHandler = async (event: React.FormEvent) => {
        event.preventDefault()

        onMenstruationEdited(menstruationEdited)
    }

    // Комментарий
    const [tempComment, setComment] = useState("")
    const commentHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value)
    }
    const saveCommentHandler = async (event: React.FormEvent) => {
        event.preventDefault()

        onSaveComment(tempComment)
    }
    const [isLoadedComment, setIsLoadedComment] = useState(false)
    if (!isLoadedComment && comment) {
        setIsLoadedComment(true)
        setComment(comment)
    }

    // Удалить цикл
    const onDeleteCycleHandler = async () => {
        onDeleteCycle()
    }

    return (
        <>
            {isOwner && isHasCycle &&
                <>
                    <form
                        className="modal-pop-form"
                        onSubmit={setMenstruationWasHandler}
                    >
                        <span className="modal-pop-label">Начало месячных (в текущем цикле)?</span>
                        <input
                            className="modal-pop-input"
                            type="radio"
                            id="menstruationWas"
                            name="menstruationWas"
                            value="was"
                            checked={menstruationWas}
                            onChange={menstruationWasHandler}
                        />
                        <span className="modal-pop-label">Конец месячных (в текущем цикле)?</span>
                        <input
                            className="modal-pop-input"
                            type="radio"
                            id="menstruationWas"
                            name="menstruationWas"
                            value="wasnot"
                            checked={!menstruationWas}
                            onChange={menstruationWasHandler}
                        />
                        {menstruationWasLoading &&
                            <button type="submit" className="modal-pop-button-animation">Сохранение...</button>}
                        {!menstruationWasLoading && <button type="submit"
                                                            className="modal-pop-button">Сохранить {menstruationWasLoadingError ? `(${menstruationWasLoadingError})` : ""}</button>}
                    </form>

                    <div style={{height: "2px", width: "100%", margin: "10px 0"}} className="bg-gray"> </div>
                </>
            }

            {isOwner && !isHasCycle &&
                <>
                    <form
                        className="modal-pop-form"
                        onSubmit={setMenstruationEditedHandler}
                    >
                        <span className="modal-pop-label">Месячные начались раньше (для существующего цикла)?</span>
                        <input
                            className="modal-pop-input"
                            type="radio"
                            id="menstruationEdited"
                            name="menstruationEdited"
                            value="start"
                            checked={menstruationEdited}
                            onChange={menstruationEditedHandler}
                        />
                        <span className="modal-pop-label">Месячные закончились позже (для существующего цикла)?</span>
                        <input
                            className="modal-pop-input"
                            type="radio"
                            id="menstruationEdited"
                            name="menstruationEdited"
                            value="finish"
                            checked={!menstruationEdited}
                            onChange={menstruationEditedHandler}
                        />
                        {menstruationEditedLoading &&
                            <button type="submit" className="modal-pop-button-animation">Сохранение...</button>}
                        {!menstruationEditedLoading && <button type="submit"
                                                               className="modal-pop-button">Сохранить {menstruationEditedLoadingError ? `(${menstruationEditedLoadingError})` : ""}</button>}
                    </form>

                    <div style={{height: "2px", width: "100%", margin: "10px 0"}} className="bg-gray"> </div>
                </>
            }

            {isOwner && !isHasCycle &&
                <>
                    <form
                        className="modal-pop-form"
                        onSubmit={setMenstruationStartedFinishedHandler}
                    >
                        <span className="modal-pop-label">Начались месячные (создание нового цикла)?</span>
                        <input
                            className="modal-pop-input"
                            type="radio"
                            id="menstruationStarted"
                            name="menstruationStarted"
                            value="start"
                            checked={menstruationStarted}
                            onChange={menstruationStartedHandler}
                        />
                        <span className="modal-pop-label">Закончились месячные (создание нового цикла)?</span>
                        <input
                            className="modal-pop-input"
                            type="radio"
                            id="menstruationStarted"
                            name="menstruationStarted"
                            value="finish"
                            checked={!menstruationStarted}
                            onChange={menstruationStartedHandler}
                        />
                        {menstruationStartedFinishedLoading &&
                            <button type="submit" className="modal-pop-button-animation">Сохранение...</button>}
                        {!menstruationStartedFinishedLoading && <button type="submit"
                                                                        className="modal-pop-button">Сохранить {menstruationStartedFinishedLoadingError ? `(${menstruationStartedFinishedLoadingError})` : ""}</button>}
                    </form>

                    <div style={{height: "2px", width: "100%", margin: "10px 0"}} className="bg-gray"> </div>
                </>
            }

            <>
                <form
                    className="modal-pop-form"
                    onSubmit={saveCommentHandler}
                >
                    <span className="modal-pop-label">Комментарий</span>
                    {isOwner &&
                        <input
                            className="modal-pop-input"
                            type="text"
                            id="comment"
                            name="comment"
                            value={tempComment}
                            onChange={commentHandler}
                        />
                    }
                    {!isOwner &&
                        <input
                            className="modal-pop-input-readonly"
                            type="text"
                            id="comment"
                            name="comment"
                            value={tempComment}
                            readOnly={true}
                        />
                    }
                    {isOwner && saveCommentLoading && <button type="submit" className="modal-pop-button-animation">Сохранение...</button>}
                    {isOwner && !saveCommentLoading && <button type="submit" className="modal-pop-button">Сохранить {saveCommentLoadingError ? `(${saveCommentLoadingError})` : ""}</button>}
                </form>
            </>

            <>
                {isOwner && isHasCycle &&
                    <div style={{height: "2px", width: "100%", margin: "10px 0"}} className="bg-gray"> </div>
                }

                {isOwner && isHasCycle && deleteCycleLoading && <button onClick={onDeleteCycleHandler} className="modal-pop-button-animation">Удаление цикла...</button>}
                {isOwner && isHasCycle && !deleteCycleLoading && <button onClick={onDeleteCycleHandler} className="modal-pop-button">Удалить цикл {deleteCycleLoadingError ? `(${deleteCycleLoadingError})` : ""}</button>}
            </>
        </>
    )
}