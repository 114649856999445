import React, {useState} from "react";
import {CreateUserRequest} from "../models/Users";
import {Link} from "react-router-dom";
import {MyTime} from "../lib/MyTime";
import {CreateCycleRequest} from "../models/Cycles";

interface ModalRegisterComponentProps {
    onClickRegister: (createUserRequest: CreateUserRequest, createCycleRequest: CreateCycleRequest) => void
    isLoading: boolean
    textError: string
}

export function ModalRegisterComponent({onClickRegister, isLoading, textError}: ModalRegisterComponentProps) {
    let timeNow = new Date()

    const [nickname, setNickname] = useState("")
    const [nicknameError, setNicknameError] = useState("")
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState("")
    const [cycleFrom, setCycleFrom] = useState(timeNow.toDateString())
    const [cycleFromError, setCycleFromError] = useState("")
    const [cycleTo, setCycleTo] = useState(timeNow.toDateString())
    const [cycleToError, setCycleToError] = useState("")
    const [cycleDuration, setCycleDuration] = useState(27)
    const [cycleDurationError, setCycleDurationError] = useState("")

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault()

        setNicknameError("")
        setPasswordError("")
        setNameError("")
        setCycleFromError("")
        setCycleToError("")
        setCycleDurationError("")

        if (nickname.trim().length === 0) {
            setNicknameError("Пусто!")
            return
        }
        if (password.trim().length === 0) {
            setPasswordError("Пусто!")
            return
        }
        if (name.trim().length === 0) {
            setNameError("Пусто!")
            return
        }
        if (cycleFrom.trim().length === 0) {
            setCycleFromError("Пусто!")
            return
        }
        if (cycleTo.trim().length === 0) {
            setCycleToError("Пусто!")
            return
        }
        if (cycleDuration === 0) {
            setCycleDurationError("Пусто!")
            return
        }

        let arrayCycleFrom = cycleFrom.split("-")
        let arrayCycleTo = cycleTo.split("-")

        let cFrom = new Date(`${arrayCycleFrom[0]}-${arrayCycleFrom[1]}-${arrayCycleFrom[2]}T00:00:01Z`)
        let cTo = new Date(`${arrayCycleTo[0]}-${arrayCycleTo[1]}-${arrayCycleTo[2]}T23:59:59Z`)

        onClickRegister({
            nickname: nickname,
            password: password,
            name: name,
            duration_cycle: cycleDuration,
            duration_menstruation: MyTime.shared.getCountDaysBetweenTwoDate(cFrom, cTo)
        }, {
            start_menstruation: cFrom.toISOString(),
            end_menstruation: cTo.toISOString(),
            start_menstruation_edited: cFrom.toISOString(),
            end_menstruation_edited: cTo.toISOString(),
            duration_cycle: cycleDuration,
        })
    }

    const changeNicknameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value)
    }
    const changePasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }
    const changeNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }
    const changeCycleFromHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCycleFrom(event.target.value)
    }
    const changeCycleToHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCycleTo(event.target.value)
    }
    const changeCycleDurationHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCycleDuration(+`${event.target.value}`)
    }

    let textButton = "Зарегистрироваться"
    if (isLoading) {
        textButton = "Регистрируемся..."
    } else if (textError) {
        textButton = "Ошибка! Еще раз"
    }

    return (
        <form
            className="modal-full-form"
            onSubmit={submitHandler}
        >
            {nicknameError && <span className="modal-full-label modal-full-error-color">Никнейм ({nicknameError})</span>}
            {!nicknameError && <span className="modal-full-label">Никнейм</span>}
            <input
                className="modal-full-input"
                type="text"
                id="nickname"
                name="nickname"
                placeholder="nickname"
                value={nickname}
                onChange={changeNicknameHandler}
            />

            {passwordError && <span className="modal-full-label modal-full-error-color">Пароль ({passwordError})</span>}
            {!passwordError && <span className="modal-full-label">Пароль</span>}
            <input
                className="modal-full-input"
                type="password"
                id="password"
                name="password"
                placeholder="123456"
                value={password}
                onChange={changePasswordHandler}
            />

            {nameError && <span className="modal-full-label modal-full-error-color">Имя ({nameError})</span>}
            {!nameError && <span className="modal-full-label">Имя</span>}
            <input
                className="modal-full-input"
                type="text"
                id="name"
                name="name"
                placeholder="Анна"
                value={name}
                onChange={changeNameHandler}
            />

            {cycleFromError && <span className="modal-full-label modal-full-error-color">Начало посл. менструации ({cycleFromError})</span>}
            {!cycleFromError && <span className="modal-full-label">Начало последней менструации</span>}
            <input
                className="modal-full-input"
                type="date"
                id="cycleFrom"
                name="cycleFrom"
                value={cycleFrom}
                onChange={changeCycleFromHandler}
            />

            {cycleToError && <span className="modal-full-label modal-full-error-color">Конец посл. менструации ({cycleToError})</span>}
            {!cycleToError && <span className="modal-full-label">Конец последней менструации</span>}
            <input
                className="modal-full-input"
                type="date"
                id="cycleTo"
                name="cycleTo"
                value={cycleTo}
                onChange={changeCycleToHandler}
            />

            {cycleDurationError && <span className="modal-full-label modal-full-error-color">Длительность цикла ({cycleDurationError})</span>}
            {!cycleDurationError && <span className="modal-full-label">Длительность цикла</span>}
            <input
                className="modal-full-input"
                type="number"
                id="cycleDuration"
                name="cycleDuration"
                min="1"
                max="60"
                value={cycleDuration}
                onChange={changeCycleDurationHandler}
            />


            {(nicknameError || passwordError || nameError || cycleFromError || cycleToError || cycleDurationError) && <span className="modal-full-label modal-full-error-color">Поправьте поля</span>}

            {isLoading && <button type="submit" className="modal-full-button-animation">{textButton}</button>}
            {!isLoading && <button type="submit" className="modal-full-button">{textButton}</button>}

            <Link to="/login" className="modal-full-button-helper">
                Есть аккаунт?<br />Авторизоваться
            </Link>
        </form>
    )
}