
export function getWordByCountDays(count: number) {
    if (count === 1) {
        return "день"
    } else if (2 <= count && count <= 4) {
        return "дня"
    } else if (5 <= count && count <= 20) {
        return "дней"
    } else {
        if (count % 11 === 1) {
            return "день"
        } else if (2 <= count % 11 && count % 11 <= 4) {
            return "дня"
        } else if (5 <= count % 11 && count % 11 <= 10) {
            return "дней"
        }
    }

    return "дней"
}