const TOKEN_AGE = ";max-age=2629743"
const TOKEN_PATH = ";path=/"
const TOKEN_LENGTH = 127


function getCookie(name: string) {
    let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
function setCookie(key: string, value: string) {
    document.cookie = `${key}=${value}${TOKEN_AGE}${TOKEN_PATH}`
}


export function hasToken() {
    let res = getCookie("token")
    if (res && res.length === TOKEN_LENGTH) {
        return true
    } else {
        return false
    }
}
export function setToken(value = "none") {
    setCookie("token", value)
}
export function getToken() {
    let res = getCookie("token")
    if (res && res.length === TOKEN_LENGTH) {
        return res
    } else {
        return ""
    }
}


export function setIdUser(value = "none") {
    setCookie("iduser", value)
}
export function getIdUser() {
    let res = getCookie("iduser")
    if (res) {
        return res
    } else {
        return 0
    }
}
export function setName(value = "none") {
    setCookie("name", value)
}
export function getName() {
    let res = getCookie("name")
    if (res && res.length > 1) {
        return res
    } else {
        return ""
    }
}
export function setDurationCycle(value = "none") {
    setCookie("durationcycle", value)
}
export function getDurationCycle() {
    let res = getCookie("durationcycle")
    if (res) {
        return res
    } else {
        return 0
    }
}
export function setDurationMenstruation(value = "none") {
    setCookie("durationmenstruation", value)
}
export function getDurationMenstruation() {
    let res = getCookie("durationmenstruation")
    if (res) {
        return res
    } else {
        return 0
    }
}
