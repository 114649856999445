import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {LoginPage} from "./pages/LoginPage";
import {RegisterPage} from "./pages/RegisterPage";
import {UserPage} from "./pages/UserPage";
import {NotFoundPage} from "./pages/NotFoundPage";
import {CalendarPage} from "./pages/CalendarPage";
import {IndexPage} from "./pages/IndexPage";
import {SubscriptionsPage} from "./pages/SubscriptionsPage";
import {ObserversPage} from "./pages/ObserversPage";
import {SubscriptionsOutPage} from "./pages/SubscriptionsOutPage";
import {ObserversInPage} from "./pages/ObserversInPage";
import {SubscriptionsAddPage} from "./pages/SubscriptionsAddPage";
import {DayPage} from "./pages/DayPage";

function App() {
  return (
      <>
        <Routes>
          <Route path="/">
            <Route index element={ <IndexPage />} />                                {/* DONE */}
            <Route path="login" element={ <LoginPage />} />                         {/* DONE */}
            <Route path="register" element={ <RegisterPage />} />                   {/* DONE */}

            <Route path="calendar/:id" element={ <CalendarPage />} />

            <Route path="users/:id" element={ <UserPage />} />                      {/* DONE */}
            <Route path="subscriptions" element={ <SubscriptionsPage />} />         {/* DONE */}
            <Route path="observers" element={ <ObserversPage />} />                 {/* DONE */}

            <Route path="subscriptions/add" element={ <SubscriptionsAddPage />} />  {/* DONE */}
            <Route path="subscriptions/out" element={ <SubscriptionsOutPage />} />  {/* DONE */}
            <Route path="observers/in" element={ <ObserversInPage />} />            {/* DONE */}

            <Route path="day" element={ <DayPage />} />                             {/* DONE */}

            <Route path="*" element={ <NotFoundPage />} />                          {/* DONE */}
          </Route>
        </Routes>
      </>
  )
}

export default App;
