import React from 'react'
import starWhite from '../img/svg/star-white.svg';

export interface CalendarDayComponentModel {
    idCycle: number         // ID цикла в БД
    idCycleBefore: number   // ID цилка следующего
    idCycleAfter: number   // ID цилка следующего
    idDay: number           // ID дня в БД
    number: number          // Номер
    type: number            // Тип
    marked: boolean         // Фон серее
    noticed: boolean        // Заметка
}

interface CalendarDayProps {
    dayModel: CalendarDayComponentModel
}

export function CalendarDayComponent(props: CalendarDayProps) {
    switch (props.dayModel.type) {
        case 0: // День недели
            let nameDayOfWeek = "ПН"
            switch (props.dayModel.number) {
                case 0:
                    nameDayOfWeek = "ПН"
                    break
                case 1:
                    nameDayOfWeek = "ВТ"
                    break
                case 2:
                    nameDayOfWeek = "СР"
                    break
                case 3:
                    nameDayOfWeek = "ЧТ"
                    break
                case 4:
                    nameDayOfWeek = "ПТ"
                    break
                case 5:
                    nameDayOfWeek = "СБ"
                    break
                case 6:
                    nameDayOfWeek = "ВС"
                    break
                default:
                    nameDayOfWeek = "ПН"
                    break
            }

            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <span className="calendar-day-text calendar-day-text-bold">{nameDayOfWeek}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <span className="calendar-day-text calendar-day-text-bold">{nameDayOfWeek}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <span className="calendar-day-text calendar-day-text-bold">{nameDayOfWeek}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <span className="calendar-day-text calendar-day-text-bold">{nameDayOfWeek}</span>
                        </div>
                    )
                }
            }
        case 1: // Обычный день
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        case 2: // Месячные по факту
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-circle bg-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-circle bg-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-circle bg-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-circle bg-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        case 3: // Овуляция
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-circle bg-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-circle bg-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-circle bg-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-circle bg-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        case 4: // Месячные по графику
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-thin-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-thin-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-thin-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-thin-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        case 5: // Дольше месячные, Раньше месячные
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-medium-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-medium-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-medium-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-medium-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-bold">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        case 6: // Возможно овуляция "Средняя вероятность"
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-medium-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-medium-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-medium-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-medium-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        case 7: // День другого месяцы
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <span className="calendar-day-text calendar-day-text-pale">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <span className="calendar-day-text calendar-day-text-pale">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <span className="calendar-day-text calendar-day-text-pale">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <span className="calendar-day-text calendar-day-text-pale">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        case 8: // Месячные сгенерированные
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-thin-dotted-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-thin-dotted-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-thin-dotted-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-thin-dotted-red">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        case 9: // Возможно овуляция "низкая вероятность"
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-thin-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <div className="calendar-day-ring-thin-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-thin-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <div className="calendar-day-ring-thin-blue">&nbsp;</div>
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
        default:
            if (props.dayModel.marked) {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day bg-dark-gray">
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            } else {
                if (props.dayModel.noticed) {
                    return (
                        <div className="calendar-day">
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                            <img src={starWhite} className="calendar-day-img-star" alt="Есть заметка"/>
                        </div>
                    )
                } else {
                    return (
                        <div className="calendar-day">
                            <span className="calendar-day-text calendar-day-text-lighter">{props.dayModel.number ? props.dayModel.number : ""}</span>
                        </div>
                    )
                }
            }
    }
}