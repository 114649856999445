import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {CreateUserRequest} from "../models/Users";
import {ModalUserComponent} from "../components/ModalUserComponent";
import {ModalPopComponent} from "../components/ModalPopComponent";
import {UsersService} from "../services/UsersService";
import {getIdUser, setDurationCycle, setDurationMenstruation, setIdUser, setName, setToken} from "../lib/Cookie";
import {ButtonComponent} from "../components/ButtonComponent";

export function UserPage() {
    // Параметр
    let {id} = useParams();

    // Навигация
    const navigate = useNavigate();

    // Состояния
    const [saveLoading, setSaveLoading] = useState(false)
    const [saveLoadingError, setSaveLoadingError] = useState('')

    const [userRead, setUserRead] = useState({
        id_user: 0,
        token: "",
        nickname: "",
        password: "",
        name: "",
        duration_cycle: 0,
        duration_menstruation: 0,
        time_created: "",
        status: 0,
    })
    const [loadingUserRead, setLoadingUserRead] = useState(true)
    const [loadingUserReadError, setLoadingUserReadError] = useState('Чтение пользователя')

    const [isOwner, setIsOwner] = useState(false)

    // Обработка сохранения
    const saveHandler = async (newUser: CreateUserRequest) => {
        setSaveLoading(true)
        setSaveLoadingError("")

        const {user: tempUser, error: tempError} = await UsersService.shared.UpdateUser(+`${id}`, newUser)
        setSaveLoadingError(tempError)

        if (!tempError) {
            setToken(tempUser?.token)
            setIdUser(`${tempUser?.id_user}`)
            setName(tempUser?.name)
            setDurationCycle(`${tempUser?.duration_cycle}`)
            setDurationMenstruation(`${tempUser?.duration_menstruation}`)
        }

        setSaveLoading(false)
    }
    const onClickExitHandler = () => {
        setToken("")
        navigate(`/login`)
    }

    // Обработка закрытия
    const closeHandler = () => {
        window.history.back();
    }

    // Чтение пользователя
    if (loadingUserRead) {
        UsersService.shared.ReadUser(+`${id}`).then(({user, error}) => {
            setUserRead(user)
            setLoadingUserReadError("")
            setLoadingUserRead(false)
            if (user.id_user === +`${getIdUser()}`) {
                setIsOwner(true)
            }
        }).catch((reason) => {
            setLoadingUserReadError("Сервер не отвечает")
            setLoadingUserRead(false)
        })
    }

    // Проверка ошибок

    let title = "Профиль"
    if (loadingUserRead) {
        title = "Чтение пользователя..."
    } else if (loadingUserReadError) {
        title = "Ошибка чтения, попробуйте еще раз"
    }

    return (
        <ModalPopComponent>
            <div className="modal-pop-nav">
                <div className="modal-pop-nav-buttons">
                    <div></div>

                    <div onClick={closeHandler}>
                        <ButtonComponent type={2}/>
                    </div>
                </div>

                <h1 className="modal-pop-title">{title}</h1>
            </div>

            {!loadingUserReadError &&
                <ModalUserComponent
                    userRead={userRead}
                    onClickSave={saveHandler}
                    onClickExit={onClickExitHandler}
                    isLoading={saveLoading}
                    textError={saveLoadingError}
                    isOwner={isOwner}
                />
            }
        </ModalPopComponent>
    )
}