import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useState} from "react";
import {SummaryBigComponent} from "../components/SummaryBigComponent";
import {getIdUser} from "../lib/Cookie";
import {SummarySmallComponent} from "../components/SummarySmallComponent";
import {ButtonComponent} from "../components/ButtonComponent";
import {getAfterYearMonth, getBeforeYearMonth} from "../lib/BeforeAfterMonth";
import {UsersService} from "../services/UsersService";
import {CalendarComponent} from "../components/CalendarComponent";
import {MyTime} from "../lib/MyTime";
import {getCountDaysBeforeRedDays, getProbability} from "../lib/Menstruation";

export function CalendarPage() {
    // Состояния
    let { id } = useParams();
    const [searchParam] = useSearchParams()
    // const [searchParam, setSearchParam] = useSearchParams()

    let QueryYear: number = +`${searchParam.get("year")}`    // 2022
    let QueryMonth: number = +`${searchParam.get("month")}`  // 1-12
    let timeNow = MyTime.shared.getTimeNow()
    if (!QueryYear || QueryYear === 0) {
        QueryYear = timeNow.year
    }
    if (!QueryMonth || QueryMonth === 0) {
        QueryMonth = timeNow.month + 1
    }

    const [userRead, setUserRead] = useState({
        id_user: 0,
        token: "",
        nickname: "",
        password: "",
        name: "",
        duration_cycle: 0,
        duration_menstruation: 0,
        time_created: "",
        status: 0,
    })
    const [loadingUserRead, setLoadingUserRead] = useState(true)
    const [loadingUserReadError, setLoadingUserReadError] = useState('Чтение пользователя')
    if (loadingUserRead) {
        UsersService.shared.ReadUser(+`${id}`).then(({user, error}) => {
            setUserRead(user)
            setLoadingUserReadError("")
            setLoadingUserRead(false)
        }).catch((reason) => {
            setLoadingUserReadError("Сервер не отвечает")
            setLoadingUserRead(false)
        })
    }

    const [resultCycles, setCycles] = useState([{
        id_cycle: 0,
        time_start_menstruation: "",
        time_end_menstruation: "",
        time_start_menstruation_edited: "",
        time_end_menstruation_edited: "",
        duration_cycle: 0,
        id_user: 0,
        time_created: "",
        status: 0,
    }])
    const [cyclesLoading, setCyclesLoading] = useState(true)
    const [cyclesLoadingError, setCyclesLoadingError] = useState('Чтение циклов')
    if (cyclesLoading) {
        UsersService.shared.GetCyclesByUser(+`${id}`, "", "").then(({cycles, error}) => {
            setCycles(cycles)
            setCyclesLoadingError("")
            setCyclesLoading(false)
        }).catch((reason) => {
            setCyclesLoadingError("Сервер не отвечает")
            setCyclesLoading(false)
        })
    }

    const [resultDaysComments, setDaysComments] = useState([{
        id_day_comment: 0,
        time: "",
        comment: "",
        id_user: 0,
        time_created: "",
        status: 0,
    }])
    const [daysCommentsLoading, setDaysCommentsLoading] = useState(true)
    const [daysCommentsLoadingError, setDaysCommentsLoadingError] = useState('Чтение дней с комментариями')
    if (daysCommentsLoading) {
        UsersService.shared.GetDaysByUser(+`${id}`, "", "").then(({daysComments, error}) => {
            setDaysComments(daysComments)
            setDaysCommentsLoadingError("")
            setDaysCommentsLoading(false)
        }).catch((reason) => {
            setDaysCommentsLoadingError("Сервер не отвечает")
            setDaysCommentsLoading(false)
        })
    }

    // Навигация
    const navigate = useNavigate();

    // Обработчики
    const clickBefore = () => {
        let {resultYear, resultMonth} = getBeforeYearMonth(QueryYear, QueryMonth)
        navigate(`/calendar/${id}?year=${resultYear}&month=${resultMonth}`)
    }
    const clickAfter = () => {
        let {resultYear, resultMonth} = getAfterYearMonth(QueryYear, QueryMonth)
        navigate(`/calendar/${id}?year=${resultYear}&month=${resultMonth}`)
    }
    // month = 0-11
    const onClickDayEdit = (idUser: number, idCycle: number, idCycleBefore: number, idCycleAfter: number, idDay: number, year: number, month: number, date: number) => {
        if (date !== 0) {
            let tempIdCycleFirst = 0
            let tempIdCycleLast = 0
            if (resultCycles.length) {
                tempIdCycleFirst = resultCycles[0].id_cycle
                tempIdCycleLast = resultCycles[resultCycles.length - 1].id_cycle
            }
            // month = 1-12
            navigate(`/day?iduser=${idUser}&idcycle=${idCycle}&idcyclebefore=${idCycleBefore}&idcycleafter=${idCycleAfter}&idday=${idDay}&year=${year}&month=${month+1}&date=${date}&idcyclefirst=${tempIdCycleFirst}&idcyclelast=${tempIdCycleLast}`)
        }
    }
    const onClickHome = () => {
        setLoadingUserRead(true)
        setCyclesLoading(true)
        setDaysCommentsLoading(true)
        navigate(`/calendar/${getIdUser()}`)
    }

    return (
        <div className="calendar-page">
            {(cyclesLoading || daysCommentsLoading) &&
                <span>Загрузка...</span>
            }
            {!(cyclesLoading || daysCommentsLoading) && (cyclesLoadingError || daysCommentsLoadingError) &&
                <span>Ошибка загрузки</span>
            }
            {!(cyclesLoading || daysCommentsLoading) && !(cyclesLoadingError || daysCommentsLoadingError) &&
                <>
                    <div onClick={event => {window.location.reload()}}>
                        <ButtonComponent type={10} />
                    </div>

                    <div style={{height: "5px"}}> </div>
                </>
            }
            {!(cyclesLoading || daysCommentsLoading) && !(cyclesLoadingError || daysCommentsLoadingError) &&
                <CalendarComponent
                    idUser={+`${id}`}
                    cycles={resultCycles}
                    daysComments={resultDaysComments}
                    year={+`${QueryYear}`}
                    month={+`${QueryMonth}`} // 1-12
                    onBefore={clickBefore}
                    onAfter={clickAfter}
                    onClickDayEdit={onClickDayEdit}
                />
            }

            <div className="calendar-page-navigation">
                {loadingUserRead &&
                    <span>Загрузка...</span>
                }
                {!loadingUserRead && loadingUserReadError &&
                    <span>Ошибка загрузки</span>
                }
                {!loadingUserRead && !loadingUserReadError &&
                    <div className="calendar-page-navigation-left">
                        <SummaryBigComponent
                            idUser={userRead.id_user}
                            nameUser={userRead.name}
                            daysBeforeMenstruation={getCountDaysBeforeRedDays(resultCycles[resultCycles.length-1] || {
                                id_cycle: 0,
                                time_start_menstruation: "",
                                time_end_menstruation: "",
                                time_start_menstruation_edited: "",
                                time_end_menstruation_edited: "",
                                duration_cycle: 0,
                                id_user: 0,
                                time_created: "",
                                status: 0,
                            })}
                            probability={getProbability(resultCycles[resultCycles.length-1] || {
                                id_cycle: 0,
                                time_start_menstruation: "",
                                time_end_menstruation: "",
                                time_start_menstruation_edited: "",
                                time_end_menstruation_edited: "",
                                duration_cycle: 0,
                                id_user: 0,
                                time_created: "",
                                status: 0,
                            })}
                        />

                        <SummarySmallComponent
                            daysLastCycle={resultCycles.length > 0 ? resultCycles[resultCycles.length-1].duration_cycle : 0}
                        />
                    </div>
                }

                <div className="calendar-page-navigation-right">
                    <Link to={`/users/${getIdUser()}`}>
                        <ButtonComponent
                            type={3}
                        />
                    </Link>
                    <Link to={`/subscriptions`}>
                        <ButtonComponent
                            type={6}
                        />
                    </Link>
                    <Link to={`/observers`}>
                        <ButtonComponent
                            type={5}
                        />
                    </Link>

                    <div onClick={event => {onClickHome()}}>
                        <ButtonComponent
                            type={4}
                        />
                    </div>
                    {/*<Link to={`/calendar/${getIdUser()}`}>*/}
                    {/*    */}
                    {/*</Link>*/}
                </div>
            </div>
        </div>
    )
}