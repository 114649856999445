import React, {useState} from "react";
import {LoginUserRequest} from "../models/Users";
import {ModalFullComponent} from "../components/ModalFullComponent";
import {ModalLoginComponent} from "../components/ModalLoginComponent";
import {
    setDurationCycle,
    setDurationMenstruation,
    setIdUser,
    setName,
    setToken
} from "../lib/Cookie";
import {useNavigate} from "react-router-dom";
import {MyTime} from "../lib/MyTime";
import {UsersService} from "../services/UsersService";

export function LoginPage() {
    // Состояния
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    // Навигация
    const navigate = useNavigate();

    // Время
    let timeNow = MyTime.shared.getTimeNow()
    let month = timeNow.month + 1       // 1-12
    let monthStr = `${month}`
    if (month < 10) { monthStr = "0" + monthStr }

    // Обработка нажатия "Авторизоваться"
    const loginHandler = async (loginUserRequest: LoginUserRequest) => {
        setLoading(true)

        setError("")
        const {user: tempUser, error: tempError} = await UsersService.shared.LoginUser(loginUserRequest)
        setError(tempError)

        if (!tempError) {
            setToken(tempUser?.token)
            setIdUser(`${tempUser?.id_user}`)
            setName(tempUser?.name)
            setDurationCycle(`${tempUser?.duration_cycle}`)
            setDurationMenstruation(`${tempUser?.duration_menstruation}`)
            navigate(`/calendar/${tempUser?.id_user}?year=${timeNow.year}&month=${monthStr}`)
        }

        setLoading(false)
    }

    return (
        <ModalFullComponent title="Авторизация">
            <ModalLoginComponent
                onClickLogin={loginHandler}
                isLoading={loading}
                textError={error}
            />
        </ModalFullComponent>
    )
}