import React from "react";
import {getWordByCountDays} from "../lib/ParsePhrase";

interface SummarySmallComponentProps {
    daysLastCycle: number,
}

export function SummarySmallComponent({daysLastCycle}: SummarySmallComponentProps) {
    return (
        <>
            <div className="summary-small bg-dark-black">
                <span className="summary-small-title-second">Длина последнего цикла</span>
                <span className="summary-small-text-second">{daysLastCycle} {getWordByCountDays(daysLastCycle)}</span>
            </div>
        </>
    )
}