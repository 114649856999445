import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/ModalPopComponent.css';
import './components/SummarySmallComponent.css';
import './components/SummaryBigComponent.css';
import './components/ModalFullComponent.css';
import './components/ButtonComponent.css';
import './components/CalendarDayComponent.css';
import './components/CalendarComponent.css';
import './pages/CalendarPage.css';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)