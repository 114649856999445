
// month 1-12
export function getBeforeYearMonth(year: number, month: number) {
    let resultYear = year
    let resultMonth = month

    if (month === 1) {
        resultYear--
        resultMonth = 12
        return {resultYear, resultMonth}
    }

    resultMonth--
    return {resultYear, resultMonth}
}

// month 1-12
export function getAfterYearMonth(year: number, month: number) {
    let resultYear = year
    let resultMonth = month

    if (month === 12) {
        resultYear++
        resultMonth = 1
        return {resultYear, resultMonth}
    }

    resultMonth++
    return {resultYear, resultMonth}
}