import React from "react";
import {MyTime} from "../lib/MyTime";
import {getWordByCountDays} from "../lib/ParsePhrase";

interface SummaryBigComponentProps {
    idUser: number,
    nameUser: string,
    daysBeforeMenstruation: number,
    probability: string,
}

export function SummaryBigComponent({idUser, nameUser, daysBeforeMenstruation, probability}: SummaryBigComponentProps) {
    let timeNow = MyTime.shared.getTimeNow()

    return (
        <>
            <div className="summary-big bg-dark-black">
                <span className="summary-big-title-first">{timeNow.date} {timeNow.monthParse}, {timeNow.dayOfWeekParse}</span>
                <span className="summary-big-text-first">Сегодня [ID {idUser}] {nameUser}</span>

                {daysBeforeMenstruation !== 0 &&
                    <span className="summary-big-title-second">До месячных</span>
                }
                {daysBeforeMenstruation !== 0 &&
                    <span className="summary-big-text-second">{daysBeforeMenstruation} {getWordByCountDays(daysBeforeMenstruation)}</span>
                }

                {daysBeforeMenstruation === 0 &&
                    <span className="summary-big-title-second">Месячные</span>
                }
                {daysBeforeMenstruation === 0 &&
                    <span className="summary-big-text-second">Сегодня</span>
                }

                <span className="summary-big-title-third">Вероятность забеременеть</span>
                <span className="summary-big-text-third">{probability}</span>
            </div>
        </>
    )
}