import React, {useState} from "react";
import {LoginUserRequest} from "../models/Users";
import {Link} from "react-router-dom";

interface ModalLoginComponentProps {
    onClickLogin: (loginUserRequest: LoginUserRequest) => void
    isLoading: boolean
    textError: string
}

export function ModalLoginComponent({onClickLogin, isLoading, textError}: ModalLoginComponentProps) {
    const [nickname, setNickname] = useState("")
    const [nicknameError, setNicknameError] = useState("")
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault()

        setNicknameError("")
        setPasswordError("")

        if (nickname.trim().length === 0) {
            setNicknameError("Пусто!")
            return
        }
        if (password.trim().length === 0) {
            setPasswordError("Пусто!")
            return
        }

        onClickLogin({
            nickname: nickname,
            password: password
        })
    }

    const changeNicknameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value)
    }
    const changePasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    let textButton = "Войти"
    if (isLoading) {
        textButton = "Входим..."
    } else if (textError) {
        textButton = "Ошибка! Еще раз"
    }

    return (
        <form
            className="modal-full-form"
            onSubmit={submitHandler}
        >
            {nicknameError && <span className="modal-full-label modal-full-error-color">Никнейм ({nicknameError})</span>}
            {!nicknameError && <span className="modal-full-label">Никнейм</span>}
            <input
                className="modal-full-input"
                type="text"
                id="nickname"
                name="nickname"
                placeholder="nickname"
                value={nickname}
                onChange={changeNicknameHandler}
            />

            {passwordError && <span className="modal-full-label modal-full-error-color">Пароль ({passwordError})</span>}
            {!passwordError && <span className="modal-full-label">Пароль</span>}
            <input
                className="modal-full-input"
                type="password"
                id="password"
                name="password"
                placeholder="123456"
                value={password}
                onChange={changePasswordHandler}
            />


            {(nicknameError || passwordError) && <span className="modal-full-label modal-full-error-color">Поправьте поля</span>}

            {isLoading && <button type="submit" className="modal-full-button-animation">{textButton}</button>}
            {!isLoading && <button type="submit" className="modal-full-button">{textButton}</button>}

            <Link to="/register" className="modal-full-button-helper">
                Нет аккаунта?<br />Зарегистрироваться
            </Link>
        </form>
    )
}