export class MyTimeStruct {
    public month: number;
    public monthParse: string;
    public date: number;
    public dayOfWeek: number;
    public dayOfWeekParse: string;
    public year: number;
    public countDays: number;
    public startDayOfWeek: number;
    public startDayOfWeekParse: string;

    constructor(month = 0, monthParse = "Январь", date = 1, dayOfWeek = 0, dayOfWeekParse = "Понедельник", year = 2022, countDays = 31, startDayOfWeek = 0, startDayOfWeekParse = "Понедельник") {
        this.month = month                              // 0-11
        this.monthParse = monthParse                    // Январь-Декабрь
        this.date = date                                // 1-31
        this.dayOfWeek = dayOfWeek                      // 0-6. 0 - воскресенье, 6 - суббота
        this.dayOfWeekParse = dayOfWeekParse            // Понедельник-Воскресенье
        this.year = year                                // 2022
        this.countDays = countDays                      // 1-31
        this.startDayOfWeek = startDayOfWeek            // 0-6. 0 - воскресенье, 6 - суббота
        this.startDayOfWeekParse = startDayOfWeekParse  // Понедельник-Воскресенье
    }
}

export class MyTime {
    static shared = new MyTime()
    private constructor() {}

    private daysInMonth(year: number = 2022, month: number = 0) {
        return 33 - new Date(year, month, 33).getDate();
    }
    private getNameMonth(index = 0){
        switch (index) {
            case 0:
                return "Январь"
            case 1:
                return "Февраль"
            case 2:
                return "Март"
            case 3:
                return "Апрель"
            case 4:
                return "Май"
            case 5:
                return "Июнь"
            case 6:
                return "Июль"
            case 7:
                return "Август"
            case 8:
                return "Сентябрь"
            case 9:
                return "Октябрь"
            case 10:
                return "Ноябрь"
            case 11:
                return "Декабрь"
        }
    }
    private getNameDayOfWeek(index = 0) {
        switch (index) {
            case 0:
                return "Воскресенье"
            case 1:
                return "Понедельник"
            case 2:
                return "Вторник"
            case 3:
                return "Среда"
            case 4:
                return "Четверг"
            case 5:
                return "Пятница"
            case 6:
                return "Суббота"
        }
    }
    private getCountDays(year = 2022, month = 0) {
        return this.daysInMonth(year, month)
    }

    public getCountDaysBetweenTwoDate(firstDate: Date, secondDate: Date) {
        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
        return Math.round(Math.abs(firstDate.getTime() - secondDate.getTime()) / oneDay)
    }
    public getCountDaysBetweenTwoTime(firstTime: MyTimeStruct, secondTime: MyTimeStruct) {
        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

        let firstDate = new Date()
        firstDate.setFullYear(firstTime.year, firstTime.month, firstTime.date)

        let secondDate = new Date()
        secondDate.setFullYear(secondTime.year, secondTime.month, secondTime.date)

        return Math.round(Math.abs(firstDate.getTime() - secondDate.getTime()) / oneDay)
    }
    public getTimeNow() {
        let now = new Date()

        let firstTimeOfMonth = new Date()
        firstTimeOfMonth.setFullYear(now.getFullYear(), now.getMonth(), 1)

        return new MyTimeStruct(now.getMonth(), this.getNameMonth(now.getMonth()), now.getDate(), now.getDay(), this.getNameDayOfWeek(now.getDay()), now.getFullYear(), this.getCountDays(now.getFullYear(), now.getMonth()), firstTimeOfMonth.getDay(), this.getNameDayOfWeek(firstTimeOfMonth.getDay()))
    }
    public getTimeBy(year = 2022, month = 0, date = 1) {
        let tempTime = new Date()
        tempTime.setFullYear(year, month, date)

        let firstTimeOfMonth = new Date()
        firstTimeOfMonth.setFullYear(year, month, 1)

        return new MyTimeStruct(month, this.getNameMonth(month), date, tempTime.getDay(), this.getNameDayOfWeek(tempTime.getDay()), year, this.getCountDays(year, month), firstTimeOfMonth.getDay(), this.getNameDayOfWeek(firstTimeOfMonth.getDay()))
    }
    public getListOfDaysOfWeek() {
        return ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]
    }
    public getListOfDaysOfWeekShort() {
        return ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"]
    }
    public getListOfMonth() {
        return [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"]
    }
}