import React from "react";
import {ReadSubscriptions} from "../models/Subscriptions";

interface ModalSubscriptionsOutComponentProps {
    subscriptionsRead: ReadSubscriptions[]

    onClickCancelUser: (idUser: number, indexOfList: number) => void

    indexOfList: number
    isLoading: boolean
    textError: string
}

export function ModalSubscriptionsOutComponent({subscriptionsRead, onClickCancelUser, indexOfList, isLoading, textError}: ModalSubscriptionsOutComponentProps) {
    let textButton = "Отменить"
    if (isLoading) {
        textButton = "Отменяем..."
    } else if (textError) {
        textButton = "Ошибка! Еще раз"
    }

    let insideHTML = []
    for (let i = 0; i < subscriptionsRead.length; i++) {
        if (indexOfList === i) {
            insideHTML.push(
                <div className="modal-pop-subscriptions-block" key={`modal-pop-subscriptions-block-${i}`}>
                    <span className="modal-pop-subscriptions-block-id" key={`modal-pop-subscriptions-block-id-${i}`}>
                    ID {subscriptionsRead[i].id_user}
                    </span>

                    <span className="modal-pop-subscriptions-block-name" key={`modal-pop-subscriptions-block-name-${i}`}>
                        {subscriptionsRead[i].name_user}
                    </span>
                    <div className="modal-pop-subscriptions-block-buttons" key={`modal-pop-subscriptions-block-buttons-${i}`}>
                        {isLoading &&
                            <span className="modal-pop-subscriptions-block-button-red-animation" key={`modal-pop-subscriptions-block-button-red-${i}`}
                              onClick={event => onClickCancelUser(subscriptionsRead[i].id_user, i)}>
                                {textButton}
                            </span>
                        }
                        {!isLoading &&
                            <span className="modal-pop-subscriptions-block-button-red" key={`modal-pop-subscriptions-block-button-red-${i}`}
                                  onClick={event => onClickCancelUser(subscriptionsRead[i].id_user, i)}>
                                {textButton}
                            </span>
                        }
                        <span key={`modal-pop-subscriptions-block-button-blue-${i}`}>
                        </span>
                    </div>
                </div>
            )
        } else {
            insideHTML.push(
                <div className="modal-pop-subscriptions-block" key={`modal-pop-subscriptions-block-${i}`}>
                    <span className="modal-pop-subscriptions-block-id" key={`modal-pop-subscriptions-block-id-${i}`}>
                    ID {subscriptionsRead[i].id_user}
                    </span>

                    <span className="modal-pop-subscriptions-block-name" key={`modal-pop-subscriptions-block-name-${i}`}>
                        {subscriptionsRead[i].name_user}
                    </span>
                    <div className="modal-pop-subscriptions-block-buttons" key={`modal-pop-subscriptions-block-buttons-${i}`}>
                        <span className="modal-pop-subscriptions-block-button-red" key={`modal-pop-subscriptions-block-button-red-${i}`}
                          onClick={event => onClickCancelUser(subscriptionsRead[i].id_user, i)}>
                            Отменить
                        </span>
                        <span key={`modal-pop-subscriptions-block-button-blue-${i}`}>
                        </span>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="modal-pop-form">
            {insideHTML}
        </div>
    )
}