import React, {useState} from "react";
import {SubscriptionRequest} from "../models/Subscriptions";
import {getIdUser} from "../lib/Cookie";
import {STATUS_SUBSCRIPTION_CREATE} from "../config/Config";

interface ModalSubscriptionsAddComponentProps {
    onCreateSubscription: (subscriptionRequest: SubscriptionRequest) => void
    isLoading: boolean
    textError: string
}

export function ModalSubscriptionsAddComponent({onCreateSubscription, isLoading, textError}: ModalSubscriptionsAddComponentProps) {
    const [idUser, setIdUser] = useState(1)
    const [idUserError, setIdUserError] = useState("")

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault()

        setIdUserError("")

        if (idUser === 0) {
            setIdUserError("Пусто!")
            return
        }

        onCreateSubscription({
            id_user: idUser,
            id_observer: +`${getIdUser()}`,
            status: STATUS_SUBSCRIPTION_CREATE,
        })
    }

    const changeIdUserHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIdUser(+`${event.target.value}`)
    }

    let textButton = "Отправить заявку"
    if (isLoading) {
        textButton = "Отправляем..."
    } else if (textError === "Успех") {
        textButton = "Успех. Отправить еще раз"
    } else if (textError) {
        textButton = "Ошибка! Еще раз"
    }

    return (
        <form
            className="modal-pop-form"
            onSubmit={submitHandler}
        >
            {idUserError && <span className="modal-pop-label modal-pop-error-color">ID пользователя ({idUserError})</span>}
            {!idUserError && <span className="modal-pop-label">ID пользователя</span>}
            <input
                className="modal-pop-input"
                type="number"
                id="idUser"
                name="idUser"
                min="1"
                value={idUser}
                onChange={changeIdUserHandler}
            />

            {idUserError && <span className="modal-pop-label modal-pop-error-color">Поправьте поля</span>}

            {isLoading && <button type="submit" className="modal-pop-button-animation">{textButton}</button>}
            {!isLoading && <button type="submit" className="modal-pop-button">{textButton}</button>}
        </form>
    )
}