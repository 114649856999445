import React from "react";

interface ModalFullComponentProps {
    children: React.ReactNode
    title: string
}

export function ModalFullComponent({ children, title }: ModalFullComponentProps) {
    return (
        <div className="modal-full">
            <h1 className="modal-full-title">{title}</h1>
            {children}
        </div>
    )
}