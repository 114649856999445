import {CreateUserRequest} from "../models/Users";
import {ModalFullComponent} from "../components/ModalFullComponent";
import React, {useState} from "react";
import {ModalRegisterComponent} from "../components/ModalRegisterComponent";
import {
    setDurationCycle,
    setDurationMenstruation,
    setIdUser,
    setName,
    setToken
} from "../lib/Cookie";
import {useNavigate} from "react-router-dom";
import {MyTime} from "../lib/MyTime";
import {UsersService} from "../services/UsersService";
import {CyclesService} from "../services/CyclesService";
import {CreateCycleRequest} from "../models/Cycles";

export function RegisterPage() {
    // Состояния
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    // Навигация
    const navigate = useNavigate();

    // Время
    let timeNow = MyTime.shared.getTimeNow()
    let month = timeNow.month + 1       // 1-12
    let monthStr = `${month}`
    if (month < 10) { monthStr = "0" + monthStr }

    // Обработка нажатия "Регистрация"
    const registerHandler = async (createUserRequest: CreateUserRequest, createCycleRequest: CreateCycleRequest) => {
        setLoading(true)

        setError("")
        const {user: tempUser, error: tempError} = await UsersService.shared.CreateUser(createUserRequest)
        setError(tempError)

        if (!tempError) {
            setLoading(true)

            setError("")
            const {error: tempError} = await CyclesService.shared.CreateCycle({
                start_menstruation: createCycleRequest.start_menstruation,
                end_menstruation: createCycleRequest.end_menstruation,
                start_menstruation_edited: createCycleRequest.start_menstruation_edited,
                end_menstruation_edited: createCycleRequest.end_menstruation_edited,
                duration_cycle: createCycleRequest.duration_cycle,
            }, tempUser?.token)
            setError(tempError)

            if (!tempError) {
                setToken(tempUser?.token)
                setIdUser(`${tempUser?.id_user}`)
                setName(tempUser?.name)
                setDurationCycle(`${tempUser?.duration_cycle}`)
                setDurationMenstruation(`${tempUser?.duration_menstruation}`)
                navigate(`/calendar/${tempUser?.id_user}?year=${timeNow.year}&month=${monthStr}`)
            }

            setLoading(false)
        }

        setLoading(false)
    }

    return (
        <ModalFullComponent title="Регистрация">
            <ModalRegisterComponent
                onClickRegister={registerHandler}
                isLoading={loading}
                textError={error}
            />
        </ModalFullComponent>
    )
}