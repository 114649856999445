import React, {useState} from "react";
import {ModalPopComponent} from "../components/ModalPopComponent";
import {ButtonComponent} from "../components/ButtonComponent";
import {SubscriptionsService} from "../services/SubscriptionsService";
import {SubscriptionRequest} from "../models/Subscriptions";
import {ModalSubscriptionsAddComponent} from "../components/ModalSubscriptionsAddComponent";

export function SubscriptionsAddPage() {
    // Состояния
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const closeHandler = () => {
        window.history.back();
    }

    // Обработка нажатия "Регистрация"
    const onCreateSubscription = async (subscriptionRequest: SubscriptionRequest) => {
        setLoading(true)

        setError("")
        const {error: tempError} = await SubscriptionsService.shared.CreateSubscription(subscriptionRequest)
        setError(tempError)

        if (!tempError) {
            setError("Успех")
        }

        setLoading(false)
    }

    let title = "Заявка на подписку в наблюдатели"

    return (
        <ModalPopComponent>
            <div className="modal-pop-nav">
                <div className="modal-pop-nav-buttons">
                    <div></div>

                    <div onClick={closeHandler}>
                        <ButtonComponent type={2}/>
                    </div>
                </div>

                <h1 className="modal-pop-title">{title}</h1>
            </div>

            <ModalSubscriptionsAddComponent
                onCreateSubscription={onCreateSubscription}
                isLoading={loading}
                textError={error}
            />
        </ModalPopComponent>
    )
}