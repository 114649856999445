import React from "react";
import {CalendarDayComponent, CalendarDayComponentModel} from './CalendarDayComponent'
import {ButtonComponent} from "./ButtonComponent";
import {Cycles} from "../models/Cycles";
import {DaysComments} from "../models/DaysComments";
import {MyTime} from "../lib/MyTime";
import {getDurationCycle, getDurationMenstruation} from "../lib/Cookie";

interface CalendarProps {
    idUser: number
    cycles: Cycles[],
    daysComments: DaysComments[]
    year: number,
    month: number,
    onBefore: () => void
    onAfter: () => void
    // month = 0-11
    onClickDayEdit: (idUser: number, idCycle: number, idCycleBefore: number, idCycleAfter: number, idDay: number, year: number, month: number, date: number) => void
}

// month 1-12
export function CalendarComponent({idUser, cycles, daysComments, year, month, onBefore, onAfter, onClickDayEdit}: CalendarProps) {
    // month 0-11
    month = month - 1
    let timeNow = MyTime.shared.getTimeNow()
    let timeTake = MyTime.shared.getTimeBy(year, month, 1)

    // Дни недели
    let daysWeek: CalendarDayComponentModel[] = []
    for (let i = 0; i < 7; i++) {
        let tempDay = {
            idCycle: 0,
            idCycleBefore: 0,
            idCycleAfter: 0,
            idDay: 0,
            number: i,
            type: 0,
            marked: false,
            noticed: false
        }
        if (year === timeNow.year && month === timeNow.month && (timeNow.dayOfWeek + 6) % 7 === i) {
            tempDay.marked = true
        }
        daysWeek.push(tempDay)
    }

    // Серый фон и даты
    let commonDays: CalendarDayComponentModel[] = []
    let numberOfDay = 1
    let flagStartNumberOfDay = false
    for (let i = 0; i < 42; i++) {
        let tempDay = {
            idCycle: 0,
            idCycleBefore: 0,
            idCycleAfter: 0,
            idDay: 0,
            number: 0,
            type: 1,
            marked: false,
            noticed: false
        }
        // Серый фон
        if (year === timeNow.year && month === timeNow.month && (timeNow.dayOfWeek+6) % 7 === i % 7) {
            tempDay.marked = true
        }
        if (year === timeNow.year && month === timeNow.month && Math.floor((((timeNow.startDayOfWeek+6) % 7) + (timeNow.date-1)) / 7) === Math.floor(i / 7)) {
            tempDay.marked = true
        }
        // Даты
        if ((timeTake.startDayOfWeek + 6) % 7 === i % 7) {
            flagStartNumberOfDay = true
        }
        if (flagStartNumberOfDay) {
            if (numberOfDay <= timeTake.countDays) {
                tempDay.number = numberOfDay
                numberOfDay++
            }
        }
        commonDays.push(tempDay)
    }

    // Циклы
    if (cycles.length) {
        for (let i = 0; i < cycles.length; i++) {
            // Прогноз "без edited"
            let timeFrom = new Date(cycles[i].time_start_menstruation)
            let timeTo = new Date(cycles[i].time_end_menstruation)
            for (let j = 0; j < commonDays.length; j++) {
                let tempTime = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${commonDays[j].number > 9 ? `${commonDays[j].number}` : `0${commonDays[j].number}`}T12:00:00Z`)
                if (commonDays[j].type === 1 && timeFrom.valueOf() <= tempTime.valueOf() && tempTime.valueOf() <= timeTo.valueOf()) {
                    commonDays[j].type = 4
                    commonDays[j].idCycle = cycles[i].id_cycle
                }
            }

            // Следующий и предыдущий цикл
            timeFrom = new Date(cycles[i].time_start_menstruation)
            timeTo = new Date(cycles[i].time_start_menstruation)
            timeTo.setDate(timeTo.getDate() + cycles[i].duration_cycle)

            let timeFromFirst = new Date(cycles[0].time_start_menstruation)
            let timeFromLast = new Date(cycles[cycles.length-1].time_start_menstruation)

            for (let j = 0; j < commonDays.length; j++) {
                let tempTime = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${commonDays[j].number > 9 ? `${commonDays[j].number}` : `0${commonDays[j].number}`}T12:00:00Z`)
                if (timeFrom.valueOf() <= tempTime.valueOf() && tempTime.valueOf() <= timeTo.valueOf()) {
                    commonDays[j].idCycleBefore = cycles[i].id_cycle
                    if (cycles.length > i + 1) {
                        commonDays[j].idCycleAfter = cycles[i+1].id_cycle
                    }
                }
                if (tempTime.valueOf() < timeFromFirst.valueOf()) {
                    commonDays[j].idCycleBefore = 0
                    commonDays[j].idCycleAfter = cycles[0].id_cycle
                } else if (timeFromLast.valueOf() < tempTime.valueOf()) {
                    commonDays[j].idCycleBefore = cycles[cycles.length-1].id_cycle
                    commonDays[j].idCycleAfter = 0
                }
            }

            // По факту
            timeFrom = new Date(cycles[i].time_start_menstruation_edited)
            timeTo = new Date(cycles[i].time_end_menstruation_edited)
            for (let j = 0; j < commonDays.length; j++) {
                let tempTime = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${commonDays[j].number > 9 ? `${commonDays[j].number}` : `0${commonDays[j].number}`}T12:00:00Z`)
                if (timeFrom.valueOf() <= tempTime.valueOf() && tempTime.valueOf() <= timeTo.valueOf()) {
                    if (commonDays[j].type === 4) {
                        commonDays[j].type = 2
                        commonDays[j].idCycle = cycles[i].id_cycle
                    } else {
                        commonDays[j].type = 5
                        commonDays[j].idCycle = cycles[i].id_cycle
                    }
                }
            }
        }

        // Предсказанные месячные
        let timeToLast = new Date(cycles[cycles.length-1].time_start_menstruation)
        timeToLast.setDate(timeToLast.getDate() + +`${getDurationCycle()}`)   // Начало нового цикла
        for (let j = 0; j < commonDays.length; j++) {
            let tempTime = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${commonDays[j].number > 9 ? `${commonDays[j].number}` : `0${commonDays[j].number}`}T12:00:00Z`)
            if (timeToLast.valueOf() <= tempTime.valueOf()) {
                if (MyTime.shared.getCountDaysBetweenTwoDate(tempTime, timeToLast) % +`${getDurationCycle()}` <= (+`${getDurationMenstruation()}` - 1)) {
                    commonDays[j].type = 8
                }
            }
        }

        // Предсказанные месячные
        timeToLast = new Date(cycles[cycles.length-1].time_start_menstruation)
        for (let j = 0; j < commonDays.length; j++) {
            let tempTime = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${commonDays[j].number > 9 ? `${commonDays[j].number}` : `0${commonDays[j].number}`}T12:00:00Z`)
            if (timeToLast.valueOf() <= tempTime.valueOf()) {
                let delta = MyTime.shared.getCountDaysBetweenTwoDate(tempTime, timeToLast) % +`${getDurationCycle()}`
                if (commonDays[j].type === 1 || commonDays[j].type === 9 || commonDays[j].type === 6 || commonDays[j].type === 3) {
                    if (+`${getDurationCycle()}`/2 - 6 <= delta && delta <= +`${getDurationCycle()}`/2 + 5) {
                        commonDays[j].type = 9
                    }
                    if (+`${getDurationCycle()}`/2 - 3 <= delta && delta <= +`${getDurationCycle()}`/2 + 2) {
                        commonDays[j].type = 6
                    }
                    if (+`${getDurationCycle()}`/2 <= delta && delta <= +`${getDurationCycle()}`/2) {
                        commonDays[j].type = 3
                    }
                }
            }
        }
    } else {
        cycles = []
    }

    // Дни с комментариями
    if (daysComments) {
        for (let i = 0; i < daysComments.length; i++) {
            let tempTime = new Date(daysComments[i].time)
            for (let j = 0; j < commonDays.length; j++) {
                let timeFrom = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${commonDays[j].number > 9 ? `${commonDays[j].number}` : `0${commonDays[j].number}`}T00:00:01Z`)
                let timeTo = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${commonDays[j].number > 9 ? `${commonDays[j].number}` : `0${commonDays[j].number}`}T23:59:59Z`)
                if (timeFrom.valueOf() <= tempTime.valueOf() && tempTime.valueOf() <= timeTo.valueOf()) {
                    commonDays[j].noticed = true
                    commonDays[j].idDay = daysComments[i].id_day_comment
                }
            }
        }
    } else {
        daysComments = []
    }

    let insideHTML = []
    for (let i = 0; i < commonDays.length / 7; i++) {
        insideHTML.push(
            <div className="calendar-week" key={`calendar-week-${i}`}>
                <div onClick={event => {onClickDayEdit(idUser, commonDays[i * 7].idCycle, commonDays[i * 7].idCycleBefore, commonDays[i * 7].idCycleAfter, commonDays[i * 7].idDay, year, month, commonDays[i * 7].number)}} key={`calendar-week-div-${i * 7}`}>
                    <CalendarDayComponent dayModel={commonDays[i * 7]} />
                </div>
                <div onClick={event => {onClickDayEdit(idUser, commonDays[i * 7 + 1].idCycle, commonDays[i * 7 + 1].idCycleBefore, commonDays[i * 7 + 1].idCycleAfter, commonDays[i * 7 + 1].idDay, year, month, commonDays[i * 7 + 1].number)}} key={`calendar-week-div-${i * 7 + 1}`}>
                    <CalendarDayComponent dayModel={commonDays[i * 7 + 1]} />
                </div>
                <div onClick={event => {onClickDayEdit(idUser, commonDays[i * 7 + 2].idCycle, commonDays[i * 7 + 2].idCycleBefore, commonDays[i * 7 + 2].idCycleAfter, commonDays[i * 7 + 2].idDay, year, month, commonDays[i * 7 + 2].number)}} key={`calendar-week-div-${i * 7 + 2}`}>
                    <CalendarDayComponent dayModel={commonDays[i * 7 + 2]} />
                </div>
                <div onClick={event => {onClickDayEdit(idUser, commonDays[i * 7 + 3].idCycle, commonDays[i * 7 + 3].idCycleBefore, commonDays[i * 7 + 3].idCycleAfter, commonDays[i * 7 + 3].idDay, year, month, commonDays[i * 7 + 3].number)}} key={`calendar-week-div-${i * 7 + 3}`}>
                    <CalendarDayComponent dayModel={commonDays[i * 7 + 3]} />
                </div>
                <div onClick={event => {onClickDayEdit(idUser, commonDays[i * 7 + 4].idCycle, commonDays[i * 7 + 4].idCycleBefore, commonDays[i * 7 + 4].idCycleAfter, commonDays[i * 7 + 4].idDay, year, month, commonDays[i * 7 + 4].number)}} key={`calendar-week-div-${i * 7 + 4}`}>
                    <CalendarDayComponent dayModel={commonDays[i * 7 + 4]} />
                </div>
                <div onClick={event => {onClickDayEdit(idUser, commonDays[i * 7 + 5].idCycle, commonDays[i * 7 + 5].idCycleBefore, commonDays[i * 7 + 5].idCycleAfter, commonDays[i * 7 + 5].idDay, year, month, commonDays[i * 7 + 5].number)}} key={`calendar-week-div-${i * 7 + 5}`}>
                    <CalendarDayComponent dayModel={commonDays[i * 7 + 5]} />
                </div>
                <div onClick={event => {onClickDayEdit(idUser, commonDays[i * 7 + 6].idCycle, commonDays[i * 7 + 6].idCycleBefore, commonDays[i * 7 + 6].idCycleAfter, commonDays[i * 7 + 6].idDay, year, month, commonDays[i * 7 + 6].number)}} key={`calendar-week-div-${i * 7 + 6}`}>
                    <CalendarDayComponent dayModel={commonDays[i * 7 + 6]} />
                </div>
            </div>
        )
    }

    return (
        <div className="calendar">
            <div className="calendar-navigation">
                <div onClick={event => {onBefore()}}>
                    <ButtonComponent type={0} />
                </div>

                <span className="calendar-navigation-title">{timeTake.monthParse} {year}</span>

                <div onClick={event => {onAfter()}}>
                    <ButtonComponent type={1} />
                </div>
            </div>

            <div className="calendar-days-of-week">
                <CalendarDayComponent dayModel={daysWeek[0]} />
                <CalendarDayComponent dayModel={daysWeek[1]} />
                <CalendarDayComponent dayModel={daysWeek[2]} />
                <CalendarDayComponent dayModel={daysWeek[3]} />
                <CalendarDayComponent dayModel={daysWeek[4]} />
                <CalendarDayComponent dayModel={daysWeek[5]} />
                <CalendarDayComponent dayModel={daysWeek[6]} />
            </div>

            {insideHTML}
        </div>
    )
}