// export const BASE_URL = "http://localhost/api/v1"
export const BASE_URL = "https://red-days.ru/api/v1"

export const STATUS_SUBSCRIPTION_DELETE = 1
export const STATUS_SUBSCRIPTION_CREATE = 2
export const STATUS_SUBSCRIPTION_CONFIRM = 3

export const STATUS_DELETE = 1
export const STATUS_ADMIN = 2
export const STATUS_USER = 3
export const STATUS_ALIVE = STATUS_USER