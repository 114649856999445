import React from 'react'
import rowLeft from '../img/svg/row-left.svg';
import rowRight from '../img/svg/row-right.svg';
import cross from '../img/svg/cross.svg';
import settings from '../img/svg/settings.svg';
import home from '../img/svg/home.svg';
import observers from '../img/svg/observers.svg';
import subscriptions from '../img/svg/subscriptions.svg';
import plus from '../img/svg/plus.svg';
import sent from '../img/svg/sent.svg';
import inbox from '../img/svg/inbox.svg';
import reload from '../img/svg/reload.svg'

interface ButtonProps {
    type: number
}

export function ButtonComponent(props: ButtonProps) {
    switch (props.type) {
        case 0: // Влево
            return (
                <div className="button">
                    <img className="button-img" src={rowLeft} alt="Стрелка влево" />
                </div>
            )
        case 1: // Вправо
            return (
                <div className="button">
                    <img className="button-img" src={rowRight} alt="Стрелка вправо" />
                </div>
            )
        case 2: // Крестик
            return (
                <div className="button">
                    <img className="button-img" src={cross} alt="Крестик" />
                </div>
            )
        case 3: // Настройки
            return (
                <div className="button">
                    <img className="button-img" src={settings} alt="Настройки" />
                </div>
            )
        case 4: // Дом
            return (
                <div className="button">
                    <img className="button-img" src={home} alt="Дом" />
                </div>
            )
        case 5: // Наблюдатели
            return (
                <div className="button">
                    <img className="button-img" src={observers} alt="Наблюдатели" />
                </div>
            )
        case 6: // Подписки
            return (
                <div className="button">
                    <img className="button-img" src={subscriptions} alt="Подписки" />
                </div>
            )
        case 7: // Плюс
            return (
                <div className="button">
                    <img className="button-img" src={plus} alt="Плюс" />
                </div>
            )
        case 8: // Исходящие
            return (
                <div className="button">
                    <img className="button-img" src={sent} alt="Исходящие" />
                </div>
            )
        case 9: // Входящие
            return (
                <div className="button">
                    <img className="button-img" src={inbox} alt="Входящие" />
                </div>
            )
        case 10:
            return (
                <div className="button">
                    <img className="button-img" src={reload} alt="Обновить" />
                </div>
            )
        default:
            return (
                <div className="button">
                    <img className="button-img" src={cross} alt="Крестик" />
                </div>
            )
    }
}