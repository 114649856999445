import {CreateUserRequest, LoginUserRequest, Users} from "../models/Users";
import {getToken} from "../lib/Cookie";
import axios, {AxiosError} from "axios";
import {BASE_URL} from "../config/Config";
import {Cycles} from "../models/Cycles";
import {DaysComments} from "../models/DaysComments";

export class UsersService {
    static shared = new UsersService()
    private constructor() {}

    public async CreateUser(createUserRequest: CreateUserRequest) {
        let user: Users = {
            id_user: 0,
            token: "",
            nickname: "",
            password: "",
            name: "",
            duration_cycle: 0,
            duration_menstruation: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const response = await axios.post<Users>(BASE_URL + `/users`, JSON.stringify(createUserRequest))
            user = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {user, error}
    }
    public async ReadUser(idUser: number) {
        let user: Users = {
            id_user: 0,
            token: "",
            nickname: "",
            password: "",
            name: "",
            duration_cycle: 0,
            duration_menstruation: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<Users>(BASE_URL + `/users/${idUser}`, config)
            user = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {user, error}
    }
    public async UpdateUser(idUser: number, updatedUser: CreateUserRequest) {
        let user: Users = {
            id_user: 0,
            token: "",
            nickname: "",
            password: "",
            name: "",
            duration_cycle: 0,
            duration_menstruation: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.patch<Users>(BASE_URL + `/users/${idUser}`, JSON.stringify(updatedUser), config)
            user = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {user, error}
    }
    public async DeleteUser(idUser: number) {
        let user: Users = {
            id_user: 0,
            token: "",
            nickname: "",
            password: "",
            name: "",
            duration_cycle: 0,
            duration_menstruation: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.delete<Users>(BASE_URL + `/users/${idUser}`, config)
            user = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {user, error}
    }
    public async GetAllUsers() {
        let users: Users[] = []
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<Users[]>(BASE_URL + `/users`, config)
            users = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {users, error}
    }

    public async LoginUser(loginUserRequest: LoginUserRequest) {
        let user: Users = {
            id_user: 0,
            token: "",
            nickname: "",
            password: "",
            name: "",
            duration_cycle: 0,
            duration_menstruation: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const response = await axios.post<Users>(BASE_URL + `/users/login`, JSON.stringify(loginUserRequest))
            user = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {user, error}
    }

    public async GetCyclesByUser(idUser: number, timeFrom: string, timeTo: string) {
        timeFrom = timeFrom.length < 3 ? "1900-01-31": timeFrom
        timeTo   = timeTo.length < 3 ? "9999-01-31": timeTo

        let cycles: Cycles[] = []
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<Cycles[]>(BASE_URL + `/users/${idUser}/cycles?timefrom=${timeFrom}&timeto=${timeTo}`, config)
            cycles = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {cycles, error}
    }
    public async GetDaysByUser(idUser: number, timeFrom: string, timeTo: string) {
        timeFrom = timeFrom.length < 3 ? "1900-01-31": timeFrom
        timeTo   = timeTo.length < 3 ? "9999-01-31": timeTo

        let daysComments: DaysComments[] = []
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<DaysComments[]>(BASE_URL + `/users/${idUser}/dayscomments?timefrom=${timeFrom}&timeto=${timeTo}`, config)
            daysComments = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {daysComments, error}
    }
}
