import {CreateDayCommentRequest, DaysComments} from "../models/DaysComments";
import {getToken} from "../lib/Cookie";
import axios, {AxiosError} from "axios";
import {BASE_URL} from "../config/Config";

export class DaysCommentsService {
    static shared = new DaysCommentsService()
    private constructor() {}

    public async CreateDayComment(createDayCommentRequest: CreateDayCommentRequest) {
        let dayComment: DaysComments = {
            id_day_comment: 0,
            time: "",
            comment: "",
            id_user: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.post<DaysComments>(BASE_URL + `/dayscomments`, JSON.stringify(createDayCommentRequest), config)
            dayComment = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {dayComment, error}
    }

    public async ReadDayComment(idDayComment: number) {
        let dayComment: DaysComments = {
            id_day_comment: 0,
            time: "",
            comment: "",
            id_user: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<DaysComments>(BASE_URL + `/dayscomments/${idDayComment}`, config)
            dayComment = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {dayComment, error}
    }

    public async UpdateDayComment(idDayComment: number, updateDayComment: DaysComments) {
        let dayComment: DaysComments = {
            id_day_comment: 0,
            time: "",
            comment: "",
            id_user: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.patch<DaysComments>(BASE_URL + `/dayscomments/${idDayComment}`, JSON.stringify(updateDayComment), config)
            dayComment = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {dayComment, error}
    }

    public async DeleteDayComment(idDayComment: number) {
        let dayComment: DaysComments = {
            id_day_comment: 0,
            time: "",
            comment: "",
            id_user: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.delete<DaysComments>(BASE_URL + `/dayscomments/${idDayComment}`, config)
            dayComment = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {dayComment, error}
    }

    public async GetAllDaysComments() {
        let daysComments: DaysComments[] = []
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<DaysComments[]>(BASE_URL + `/dayscomments`, config)
            daysComments = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {daysComments, error}
    }
}