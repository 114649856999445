import React from "react";

interface ModalPopComponentProps {
    children: React.ReactNode
}

export function ModalPopComponent({ children }: ModalPopComponentProps) {
    return (
        <div className="modal-pop">
            {children}
        </div>
    )
}