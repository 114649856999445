import React, {useState} from "react";
import {ModalPopComponent} from "../components/ModalPopComponent";
import {getIdUser} from "../lib/Cookie";
import {ButtonComponent} from "../components/ButtonComponent";
import {SubscriptionsService} from "../services/SubscriptionsService";
import {STATUS_SUBSCRIPTION_CONFIRM, STATUS_SUBSCRIPTION_DELETE} from "../config/Config";
import {ModalObserversComponent} from "../components/ModalObserversComponent";
import {useNavigate} from "react-router-dom";

export function ObserversPage() {
    const navigate = useNavigate();

    // Состояния
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingError, setIsLoadingError] = useState("")
    const [indexOfList, setIndexOfList] = useState(-1)

    const [subscriptionsRead, setSubscriptionsRead] = useState([{
        id_subscription: 0,
        id_user: 0,
        name_user: "",
        id_observer: 0,
        name_observer: "",
        time_created: "",
        status: 0,
    }])
    const [loadingSubscriptionsRead, setLoadingSubscriptionsRead] = useState(true)
    const [loadingSubscriptionsReadError, setLoadingSubscriptionsReadError] = useState('Чтение пользователей')

    // Обработчики
    const showObserversInHandler = () => {
        navigate(`/observers/in`)
    }
    const closeHandler = () => {
        window.history.back();
    }

    const onClickDeleteUser = async (idUser: number, indexOfList: number) => {
        setIndexOfList(indexOfList)
        setIsLoading(true)
        setIsLoadingError("")

        const {readSubscription: tempReadSubscription, error: tempError} = await SubscriptionsService.shared.UpdateSubscription({
            id_user: +`${getIdUser()}`,
            id_observer: idUser,
            status: STATUS_SUBSCRIPTION_DELETE,
        })
        setIsLoadingError(tempError)

        let indexDelete = -1
        if (!tempError) {
            for (let i = 0; i < subscriptionsRead.length; i++) {
                if (subscriptionsRead[i].id_user === tempReadSubscription.id_user) {
                    indexDelete = i
                }
            }
        }
        if (indexDelete !== -1) {
            subscriptionsRead.splice(indexDelete, 1);
        }

        setIsLoading(false)
    }

    // Чтение пользователей
    if (loadingSubscriptionsRead) {
        SubscriptionsService.shared.GetSubscriptions(+`${getIdUser()}`, 0, STATUS_SUBSCRIPTION_CONFIRM).then(({readSubscriptions, error}) => {
            setSubscriptionsRead(readSubscriptions)
            setLoadingSubscriptionsReadError("")
            setLoadingSubscriptionsRead(false)
        }).catch((reason) => {
            setLoadingSubscriptionsReadError("Сервер не отвечает")
            setLoadingSubscriptionsRead(false)
        })
    }

    // Проверка ошибок
    let title = "Наблюдатели"
    if (loadingSubscriptionsRead) {
        title = "Загрузка пользователей..."
    } else if (loadingSubscriptionsReadError) {
        title = "Ошибка загрузки, попробуйте еще раз"
    }

    return (
        <ModalPopComponent>
            <div className="modal-pop-nav">
                <div className="modal-pop-nav-buttons">
                    <div style={{width: "44px", height: "44px"}}></div>

                    <div onClick={showObserversInHandler}>
                        <ButtonComponent type={9}/>
                    </div>

                    <div onClick={closeHandler}>
                        <ButtonComponent type={2}/>
                    </div>
                </div>

                <h1 className="modal-pop-title">{title}</h1>
            </div>

            {!loadingSubscriptionsReadError &&
                <ModalObserversComponent
                    subscriptionsRead={subscriptionsRead}

                    onClickDeleteUser={onClickDeleteUser}

                    indexOfList={indexOfList}
                    isLoading={isLoading}
                    textError={isLoadingError}
                />
            }
        </ModalPopComponent>
    )
}