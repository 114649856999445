import React, {useState} from "react";
import {ModalPopComponent} from "../components/ModalPopComponent";
import {ButtonComponent} from "../components/ButtonComponent";
import {ModalDayComponent} from "../components/ModalDayComponent";
import {useSearchParams} from "react-router-dom";
import {MyTime} from "../lib/MyTime";
import {getDurationCycle, getDurationMenstruation, getIdUser} from "../lib/Cookie";
import {DaysCommentsService} from "../services/DaysCommentsService";
import {CyclesService} from "../services/CyclesService";

export function DayPage() {
    // Query параметры
    const [searchParam] = useSearchParams()
    let idUser: number = +`${searchParam.get("iduser")}`                // 0...
    let idCycle: number = +`${searchParam.get("idcycle")}`              // 0...
    let idCycleBefore: number = +`${searchParam.get("idcyclebefore")}`  // 0...
    let idCycleAfter: number = +`${searchParam.get("idcycleafter")}`    // 0...
    let idDay: number = +`${searchParam.get("idday")}`                  // 0...
    let year: number = +`${searchParam.get("year")}`                    // 2022
    let month: number = +`${searchParam.get("month")}` - 1              // 0-11
    let date: number = +`${searchParam.get("date")}`                    // 1-31
    let idCycleFirst: number = +`${searchParam.get("idcyclefirst")}`    // 0...
    let idCycleLast: number = +`${searchParam.get("idcyclelast")}`      // 0...
    let timeTaken = MyTime.shared.getTimeBy(year, month, date)

    // Чтение дня с комментарием
    const [dayRead, setDayRead] = useState({
        id_day_comment: 0,
        time: "",
        comment: "",
        id_user: 0,
        time_created: "",
        status: 0,
    })
    const [dayReadLoading, setDayReadLoading] = useState(true)
    const [, setDayReadLoadingError] = useState("Чтение")
    if (dayReadLoading) {
        DaysCommentsService.shared.ReadDayComment(idDay).then(({dayComment, error}) => {
            setDayRead(dayComment)
            setDayReadLoading(false)
            setDayReadLoadingError("")
        }).catch((reason) => {
            setDayReadLoading(false)
            setDayReadLoadingError("Ошибка")
        })
    }

    // Чтение цикла
    const [cycleRead, setCycleRead] = useState({
        id_cycle: 0,
        time_start_menstruation: "",
        time_end_menstruation: "",
        time_start_menstruation_edited: "",
        time_end_menstruation_edited: "",
        duration_cycle: 0,
        id_user: 0,
        time_created: "",
        status: 0,
    })
    const [cycleReadLoading, setCycleReadLoading] = useState(true)
    const [, setCycleReadLoadingError] = useState("Чтение")
    if (cycleReadLoading) {
        CyclesService.shared.ReadCycle(idCycle).then(({cycle, error}) => {
            setCycleRead(cycle)
            setCycleReadLoading(false)
            setCycleReadLoadingError("")
        }).catch((reason) => {
            setCycleReadLoading(false)
            setCycleReadLoadingError("Ошибка")
        })
    }

    // Чтение цикла самого первого
    const [cycleReadFirst, setCycleReadFirst] = useState({
        id_cycle: 0,
        time_start_menstruation: "",
        time_end_menstruation: "",
        time_start_menstruation_edited: "",
        time_end_menstruation_edited: "",
        duration_cycle: 0,
        id_user: 0,
        time_created: "",
        status: 0,
    })
    const [cycleReadFirstLoading, setCycleReadFirstLoading] = useState(true)
    const [, setCycleReadFirstLoadingError] = useState("Чтение")
    if (cycleReadFirstLoading) {
        CyclesService.shared.ReadCycle(idCycleFirst).then(({cycle, error}) => {
            setCycleReadFirst(cycle)
            setCycleReadFirstLoading(false)
            setCycleReadFirstLoadingError("")
        }).catch((reason) => {
            setCycleReadFirstLoading(false)
            setCycleReadFirstLoadingError("Ошибка")
        })
    }

    // Чтение цикла самого последнего
    const [cycleReadLast, setCycleReadLast] = useState({
        id_cycle: 0,
        time_start_menstruation: "",
        time_end_menstruation: "",
        time_start_menstruation_edited: "",
        time_end_menstruation_edited: "",
        duration_cycle: 0,
        id_user: 0,
        time_created: "",
        status: 0,
    })
    const [cycleReadLastLoading, setCycleReadLastLoading] = useState(true)
    const [, setCycleReadLastLoadingError] = useState("Чтение")
    if (cycleReadLastLoading) {
        CyclesService.shared.ReadCycle(idCycleLast).then(({cycle, error}) => {
            setCycleReadLast(cycle)
            setCycleReadLastLoading(false)
            setCycleReadLastLoadingError("")
        }).catch((reason) => {
            setCycleReadLastLoading(false)
            setCycleReadLastLoadingError("Ошибка")
        })
    }

    // Чтение цикла предыдущего
    const [cycleReadBefore, setCycleReadBefore] = useState({
        id_cycle: 0,
        time_start_menstruation: "",
        time_end_menstruation: "",
        time_start_menstruation_edited: "",
        time_end_menstruation_edited: "",
        duration_cycle: 0,
        id_user: 0,
        time_created: "",
        status: 0,
    })
    const [cycleReadBeforeLoading, setCycleReadBeforeLoading] = useState(true)
    const [, setCycleReadBeforeLoadingError] = useState("Чтение")
    if (cycleReadBeforeLoading) {
        CyclesService.shared.ReadCycle(idCycleBefore).then(({cycle, error}) => {
            setCycleReadBefore(cycle)
            setCycleReadBeforeLoading(false)
            setCycleReadBeforeLoadingError("")
        }).catch((reason) => {
            setCycleReadBeforeLoading(false)
            setCycleReadBeforeLoadingError("Ошибка")
        })
    }

    // Чтение цикла следующего
    const [cycleReadAfter, setCycleReadAfter] = useState({
        id_cycle: 0,
        time_start_menstruation: "",
        time_end_menstruation: "",
        time_start_menstruation_edited: "",
        time_end_menstruation_edited: "",
        duration_cycle: 0,
        id_user: 0,
        time_created: "",
        status: 0,
    })
    const [cycleReadAfterLoading, setCycleReadAfterLoading] = useState(true)
    const [, setCycleReadAfterLoadingError] = useState("Чтение")
    if (cycleReadAfterLoading) {
        CyclesService.shared.ReadCycle(idCycleAfter).then(({cycle, error}) => {
            setCycleReadAfter(cycle)
            setCycleReadAfterLoading(false)
            setCycleReadAfterLoadingError("")
        }).catch((reason) => {
            setCycleReadAfterLoading(false)
            setCycleReadAfterLoadingError("Ошибка")
        })
    }

    // Кнопка закрыть
    const closeHandler = () => {
        window.history.back();
    }

    // Обработка нажатия "Были ли месячные"
    const [menstruationWasLoading, setMenstruationWasLoading] = useState(false)
    const [menstruationWasLoadingError, setMenstruationWasLoadingError] = useState("")
    const onMenstruationWas = async (menstruationWas: boolean) => {
        setMenstruationWasLoading(true)

        setMenstruationWasLoadingError("")
        if (menstruationWas) {  // Начало месячных
            let tempCycle = {
                id_cycle: cycleRead.id_cycle,
                time_start_menstruation: cycleRead.time_start_menstruation,
                time_end_menstruation: cycleRead.time_end_menstruation,
                time_start_menstruation_edited: `${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${date > 9 ? `${date}` : `0${date}`}T00:00:01Z`,
                time_end_menstruation_edited: cycleRead.time_end_menstruation_edited,
                duration_cycle: cycleRead.duration_cycle,
                id_user: cycleRead.id_user,
                time_created: cycleRead.time_created,
                status: cycleRead.status,
            }
            const {error} = await CyclesService.shared.UpdateCycle(idCycle, tempCycle)
            if (error) {
                setMenstruationWasLoadingError("Ошибка")
            }
        } else {    // Конец месячных
            let tempCycle = {
                id_cycle: cycleRead.id_cycle,
                time_start_menstruation: cycleRead.time_start_menstruation,
                time_end_menstruation: cycleRead.time_end_menstruation,
                time_start_menstruation_edited: cycleRead.time_start_menstruation_edited,
                time_end_menstruation_edited: `${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${date > 9 ? `${date}` : `0${date}`}T23:59:59Z`,
                duration_cycle: cycleRead.duration_cycle,
                id_user: cycleRead.id_user,
                time_created: cycleRead.time_created,
                status: cycleRead.status,
            }
            const {error} = await CyclesService.shared.UpdateCycle(idCycle, tempCycle)
            if (error) {
                setMenstruationWasLoadingError("Ошибка")
            }
        }

        setMenstruationWasLoading(false)
    }

    // Обработка нажатия "Начало или конец месячных"
    const [menstruationStartedFinishedLoading, setMenstruationStartedFinishedLoading] = useState(false)
    const [menstruationStartedFinishedLoadingError, setMenstruationStartedFinishedLoadingError] = useState("")
    const onMenstruationStartedFinished = async (menstruationStarted: boolean) => {
        setMenstruationStartedFinishedLoading(true)

        setMenstruationStartedFinishedLoadingError("")
        if (menstruationStarted) {  // Начало
            let timeFrom = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${date > 9 ? `${date}` : `0${date}`}T00:00:01Z`)
            let timeTo = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${date > 9 ? `${date}` : `0${date}`}T23:59:59Z`)
            timeFrom.setDate(timeFrom.getDate())
            timeTo.setDate(timeTo.getDate() + (+`${getDurationMenstruation()}` - 1))
            let tempCycle = {
                start_menstruation: timeFrom.toISOString(),
                end_menstruation: timeTo.toISOString(),
                start_menstruation_edited: timeFrom.toISOString(),
                end_menstruation_edited: timeTo.toISOString(),
                duration_cycle: +`${getDurationCycle()}`,
            }
            const {error} = await CyclesService.shared.CreateCycle(tempCycle)
            if (error) {
                setMenstruationStartedFinishedLoadingError("Ошибка")
            }
        } else {    // Конец
            let timeFrom = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${date > 9 ? `${date}` : `0${date}`}T00:00:01Z`)
            let timeTo = new Date(`${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${date > 9 ? `${date}` : `0${date}`}T23:59:59Z`)
            timeFrom.setDate(timeFrom.getDate() - (+`${getDurationMenstruation()}` - 1))
            timeTo.setDate(timeTo.getDate())
            let tempCycle = {
                start_menstruation: timeFrom.toISOString(),
                end_menstruation: timeTo.toISOString(),
                start_menstruation_edited: timeFrom.toISOString(),
                end_menstruation_edited: timeTo.toISOString(),
                duration_cycle: +`${getDurationCycle()}`,
            }
            const {error} = await CyclesService.shared.CreateCycle(tempCycle)
            if (error) {
                setMenstruationStartedFinishedLoadingError("Ошибка")
            }
        }

        setMenstruationStartedFinishedLoading(false)
    }

    // Обработка нажатия "Начались раньше или позже"
    const [menstruationEditedLoading, setMenstruationEditedLoading] = useState(false)
    const [menstruationEditedLoadingError, setMenstruationEditedLoadingError] = useState("")
    const onMenstruationEdited = async (menstruationStarted: boolean) => {
        setMenstruationEditedLoading(true)

        setMenstruationEditedLoadingError("")
        if (menstruationStarted) {  // Начало
            if (idCycleAfter !== 0) {
                let tempCycle = {
                    id_cycle: cycleReadAfter.id_cycle,
                    time_start_menstruation: cycleReadAfter.time_start_menstruation,
                    time_end_menstruation: cycleReadAfter.time_end_menstruation,
                    time_start_menstruation_edited: `${year}-${month + 1 > 9 ? `${month + 1}` : `0${month + 1}`}-${date > 9 ? `${date}` : `0${date}`}T00:00:01Z`,
                    time_end_menstruation_edited: cycleReadAfter.time_end_menstruation_edited,
                    duration_cycle: cycleReadAfter.duration_cycle,
                    id_user: cycleReadAfter.id_user,
                    time_created: cycleReadAfter.time_created,
                    status: cycleReadAfter.status,
                }
                const {error} = await CyclesService.shared.UpdateCycle(idCycleAfter, tempCycle)
                if (error) {
                    setMenstruationEditedLoadingError("Ошибка")
                }
            } else {
                let tempCycle = {
                    id_cycle: cycleReadLast.id_cycle,
                    time_start_menstruation: cycleReadLast.time_start_menstruation,
                    time_end_menstruation: cycleReadLast.time_end_menstruation,
                    time_start_menstruation_edited: `${year}-${month + 1 > 9 ? `${month + 1}` : `0${month + 1}`}-${date > 9 ? `${date}` : `0${date}`}T00:00:01Z`,
                    time_end_menstruation_edited: cycleReadLast.time_end_menstruation_edited,
                    duration_cycle: cycleReadLast.duration_cycle,
                    id_user: cycleReadLast.id_user,
                    time_created: cycleReadLast.time_created,
                    status: cycleReadLast.status,
                }
                const {error} = await CyclesService.shared.UpdateCycle(idCycleLast, tempCycle)
                if (error) {
                    setMenstruationEditedLoadingError("Ошибка")
                }
            }
        } else {    // Конец
            if (idCycleBefore !== 0) {
                let tempCycle = {
                    id_cycle: cycleReadBefore.id_cycle,
                    time_start_menstruation: cycleReadBefore.time_start_menstruation,
                    time_end_menstruation: cycleReadBefore.time_end_menstruation,
                    time_start_menstruation_edited: cycleReadBefore.time_start_menstruation_edited,
                    time_end_menstruation_edited: `${year}-${month + 1 > 9 ? `${month + 1}` : `0${month + 1}`}-${date > 9 ? `${date}` : `0${date}`}T23:59:59Z`,
                    duration_cycle: cycleReadBefore.duration_cycle,
                    id_user: cycleReadBefore.id_user,
                    time_created: cycleReadBefore.time_created,
                    status: cycleReadBefore.status,
                }
                const {error} = await CyclesService.shared.UpdateCycle(idCycleBefore, tempCycle)
                if (error) {
                    setMenstruationEditedLoadingError("Ошибка")
                }
            } else {
                let tempCycle = {
                    id_cycle: cycleReadFirst.id_cycle,
                    time_start_menstruation: cycleReadFirst.time_start_menstruation,
                    time_end_menstruation: cycleReadFirst.time_end_menstruation,
                    time_start_menstruation_edited: cycleReadFirst.time_start_menstruation_edited,
                    time_end_menstruation_edited: `${year}-${month + 1 > 9 ? `${month + 1}` : `0${month + 1}`}-${date > 9 ? `${date}` : `0${date}`}T23:59:59Z`,
                    duration_cycle: cycleReadFirst.duration_cycle,
                    id_user: cycleReadFirst.id_user,
                    time_created: cycleReadFirst.time_created,
                    status: cycleReadFirst.status,
                }
                const {error} = await CyclesService.shared.UpdateCycle(idCycleFirst, tempCycle)
                if (error) {
                    setMenstruationEditedLoadingError("Ошибка")
                }
            }
        }

        setMenstruationEditedLoading(false)
    }

    // Обработка нажатия "Сохранить комментарий"
    const [saveCommentLoading, setSaveCommentLoading] = useState(false)
    const [saveCommentLoadingError, setSaveCommentLoadingError] = useState("")
    const onSaveComment = async (comment: string) => {
        setSaveCommentLoading(true)

        setSaveCommentLoadingError("")
        if (idDay !== 0 && comment) {
            let tempDayComment = dayRead
            tempDayComment.comment = comment
            const {error} = await DaysCommentsService.shared.UpdateDayComment(idDay, tempDayComment)
            if (error) {
                setSaveCommentLoadingError("Ошибка")
            }
        } else if (idDay === 0 && comment) {
            let tempDayComment = {
                time: `${year}-${month+1 > 9 ? `${month+1}` : `0${month+1}`}-${date > 9 ? `${date}` : `0${date}`}T12:00:00Z`,
                comment: comment,
            }
            const {error} = await DaysCommentsService.shared.CreateDayComment(tempDayComment)
            if (error) {
                setSaveCommentLoadingError("Ошибка")
            }
        } else if (idDay !== 0 && !comment) {
            const {error} = await DaysCommentsService.shared.DeleteDayComment(idDay)
            if (error) {
                setSaveCommentLoadingError("Ошибка")
            }
        }

        setSaveCommentLoading(false)
    }

    // Обработка нажатия "Удалить цикл"
    const [deleteCycleLoading, setDeleteCycleLoading] = useState(false)
    const [deleteCycleLoadingError, setDeleteCycleLoadingError] = useState("")
    const onDeleteCycle = async () => {
        setDeleteCycleLoading(true)

        setDeleteCycleLoadingError("")
        const {error} = await CyclesService.shared.DeleteCycle(idCycle)
        if (error) {
            setDeleteCycleLoadingError("Ошибка")
        }

        setDeleteCycleLoading(false)
    }

    return (
        <ModalPopComponent>
            <div className="modal-pop-nav">
                <div className="modal-pop-nav-buttons">
                    <div></div>

                    <div onClick={closeHandler}>
                        <ButtonComponent type={2}/>
                    </div>
                </div>

                <h1 className="modal-pop-title">{`${date} ${timeTaken.monthParse} ${year}`}</h1>
            </div>

            <ModalDayComponent
                isOwner={idUser === +`${getIdUser()}`}
                comment={dayRead.comment}
                isHasCycle={idCycle !== 0}

                onMenstruationWas={onMenstruationWas}
                menstruationWasLoading={menstruationWasLoading}
                menstruationWasLoadingError={menstruationWasLoadingError}

                onMenstruationStartedFinished={onMenstruationStartedFinished}
                menstruationStartedFinishedLoading={menstruationStartedFinishedLoading}
                menstruationStartedFinishedLoadingError={menstruationStartedFinishedLoadingError}

                onMenstruationEdited={onMenstruationEdited}
                menstruationEditedLoading={menstruationEditedLoading}
                menstruationEditedLoadingError={menstruationEditedLoadingError}

                onSaveComment={onSaveComment}
                saveCommentLoading={saveCommentLoading}
                saveCommentLoadingError={saveCommentLoadingError}

                onDeleteCycle={onDeleteCycle}
                deleteCycleLoading={deleteCycleLoading}
                deleteCycleLoadingError={deleteCycleLoadingError}
            />
        </ModalPopComponent>
    )
}