import React, {useState} from "react";
import {ModalPopComponent} from "../components/ModalPopComponent";
import {getIdUser} from "../lib/Cookie";
import {ButtonComponent} from "../components/ButtonComponent";
import {SubscriptionsService} from "../services/SubscriptionsService";
import {STATUS_SUBSCRIPTION_CONFIRM, STATUS_SUBSCRIPTION_CREATE, STATUS_SUBSCRIPTION_DELETE} from "../config/Config";
import {ModalObserversInComponent} from "../components/ModalObserversInComponent";

export function ObserversInPage() {
    // Состояния
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [isLoadingDeleteError, setIsLoadingDeleteError] = useState("")
    const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
    const [isLoadingConfirmError, setIsLoadingConfirmError] = useState("")
    const [indexOfList, setIndexOfList] = useState(-1)

    const [subscriptionsRead, setSubscriptionsRead] = useState([{
        id_subscription: 0,
        id_user: 0,
        name_user: "",
        id_observer: 0,
        name_observer: "",
        time_created: "",
        status: 0,
    }])
    const [loadingSubscriptionsRead, setLoadingSubscriptionsRead] = useState(true)
    const [loadingSubscriptionsReadError, setLoadingSubscriptionsReadError] = useState('Чтение пользователей')

    // Обработчики
    const closeHandler = () => {
        window.history.back();
    }
    const onClickDeleteUser = async (idUser: number, indexOfList: number) => {
        setIndexOfList(indexOfList)
        setIsLoadingDelete(true)
        setIsLoadingDeleteError("")

        const {readSubscription: tempReadSubscription, error: tempError} = await SubscriptionsService.shared.UpdateSubscription({
            id_user: +`${getIdUser()}`,
            id_observer: idUser,
            status: STATUS_SUBSCRIPTION_DELETE,
        })
        setIsLoadingDeleteError(tempError)

        let indexDelete = -1
        if (!tempError) {
            for (let i = 0; i < subscriptionsRead.length; i++) {
                if (subscriptionsRead[i].id_user === tempReadSubscription.id_user) {
                    indexDelete = i
                }
            }
        }
        if (indexDelete !== -1) {
            subscriptionsRead.splice(indexDelete, 1);
        }

        setIsLoadingDelete(false)
    }
    const onClickConfirmUser = async (idUser: number, indexOfList: number) => {
        setIndexOfList(indexOfList)
        setIsLoadingConfirm(true)
        setIsLoadingConfirmError("")

        const {readSubscription: tempReadSubscription, error: tempError} = await SubscriptionsService.shared.UpdateSubscription({
            id_user: +`${getIdUser()}`,
            id_observer: idUser,
            status: STATUS_SUBSCRIPTION_CONFIRM,
        })
        setIsLoadingConfirmError(tempError)

        let indexDelete = -1
        if (!tempError) {
            for (let i = 0; i < subscriptionsRead.length; i++) {
                if (subscriptionsRead[i].id_user === tempReadSubscription.id_user) {
                    indexDelete = i
                }
            }
        }
        if (indexDelete !== -1) {
            subscriptionsRead.splice(indexDelete, 1);
        }

        setIsLoadingConfirm(false)
    }

    // Чтение пользователей
    if (loadingSubscriptionsRead) {
        SubscriptionsService.shared.GetSubscriptions(+`${getIdUser()}`, 0, STATUS_SUBSCRIPTION_CREATE).then(({readSubscriptions, error}) => {
            setSubscriptionsRead(readSubscriptions)
            setLoadingSubscriptionsReadError("")
            setLoadingSubscriptionsRead(false)
        }).catch((reason) => {
            setLoadingSubscriptionsReadError("Сервер не отвечает")
            setLoadingSubscriptionsRead(false)
        })
    }

    // Проверка ошибок
    let title = "Входящие заявки"
    if (loadingSubscriptionsRead) {
        title = "Загрузка пользователей..."
    } else if (loadingSubscriptionsReadError) {
        title = "Ошибка загрузки, попробуйте еще раз"
    }

    return (
        <ModalPopComponent>
            <div className="modal-pop-nav">
                <div className="modal-pop-nav-buttons">
                    <div></div>

                    <div onClick={closeHandler}>
                        <ButtonComponent type={2}/>
                    </div>
                </div>

                <h1 className="modal-pop-title">{title}</h1>
            </div>

            {!loadingSubscriptionsReadError &&
                <ModalObserversInComponent
                    subscriptionsRead={subscriptionsRead}

                    onClickDeleteUser={onClickDeleteUser}
                    onClickConfirmUser={onClickConfirmUser}

                    indexOfList={indexOfList}
                    isLoadingDelete={isLoadingDelete}
                    isLoadingDeleteError={isLoadingDeleteError}
                    isLoadingConfirm={isLoadingConfirm}
                    isLoadingConfirmError={isLoadingConfirmError}
                />
            }
        </ModalPopComponent>
    )
}