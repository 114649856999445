import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {MyTime} from "../lib/MyTime";
import {
    getIdUser,
    hasToken,
} from "../lib/Cookie";
import {LoginPage} from "./LoginPage";

export function IndexPage() {
    // Навигация
    const navigate = useNavigate();

    // Время
    let timeNow = MyTime.shared.getTimeNow()
    let month = timeNow.month + 1       // 1-12
    let monthStr = `${month}`
    if (month < 10) { monthStr = "0" + monthStr }

    // Проверка "Авторизован ли уже"
    useEffect(() => {
        if (hasToken()) {
            navigate(`/calendar/${getIdUser()}?year=${timeNow.year}&month=${monthStr}`)
        }
    })

    return (
        <LoginPage />
    )
}