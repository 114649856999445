import axios, {AxiosError} from "axios";
import {BASE_URL} from "../config/Config";
import {getToken} from "../lib/Cookie";
import {ReadSubscriptions, SubscriptionRequest} from "../models/Subscriptions";

export class SubscriptionsService {
    static shared = new SubscriptionsService()
    private constructor() {}

    public async CreateSubscription(subscriptionRequest: SubscriptionRequest) {
        let readSubscription: ReadSubscriptions = {
            id_subscription: 0,
            id_user: 0,
            name_user: "",
            id_observer: 0,
            name_observer: "",
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.post<ReadSubscriptions>(BASE_URL + `/subscriptions`, JSON.stringify(subscriptionRequest), config)
            readSubscription = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {readSubscription, error}
    }

    public async UpdateSubscription(subscriptionRequest: SubscriptionRequest) {
        let readSubscription: ReadSubscriptions = {
            id_subscription: 0,
            id_user: 0,
            name_user: "",
            id_observer: 0,
            name_observer: "",
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.put<ReadSubscriptions>(BASE_URL + `/subscriptions`, JSON.stringify(subscriptionRequest), config)
            readSubscription = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {readSubscription, error}
    }

    public async GetSubscriptions(idUser: number = 0, idObserver: number = 0, status: number = 0) {
        let readSubscriptions: ReadSubscriptions[] = []
        let error = ""

        let query = "?"
        if (idUser !== 0) {
            query += `iduser=${idUser}&`
        }
        if (idObserver !== 0) {
            query += `idobserver=${idObserver}&`
        }
        if (status !== 0) {
            query += `status=${status}`
        }

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<ReadSubscriptions[]>(BASE_URL + `/subscriptions${query}`, config)
            readSubscriptions = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {readSubscriptions, error}
    }
}