import {CreateCycleRequest, Cycles} from "../models/Cycles";
import axios, {AxiosError} from "axios";
import {BASE_URL} from "../config/Config";
import {getToken} from "../lib/Cookie";

export class CyclesService {
    static shared = new CyclesService()
    private constructor() {}

    public async CreateCycle(createCycleRequest: CreateCycleRequest, optionalToken: string = "") {
        let cycle: Cycles = {
            id_cycle: 0,
            time_start_menstruation: "",
            time_end_menstruation: "",
            time_start_menstruation_edited: "",
            time_end_menstruation_edited: "",
            duration_cycle: 0,
            id_user: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken() || optionalToken
                }
            }
            const response = await axios.post<Cycles>(BASE_URL + `/cycles`, JSON.stringify(createCycleRequest), config)
            cycle = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {cycle, error}
    }

    public async ReadCycle(idCycle: number) {
        let cycle: Cycles = {
            id_cycle: 0,
            time_start_menstruation: "",
            time_end_menstruation: "",
            time_start_menstruation_edited: "",
            time_end_menstruation_edited: "",
            duration_cycle: 0,
            id_user: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<Cycles>(BASE_URL + `/cycles/${idCycle}`, config)
            cycle = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {cycle, error}
    }

    public async UpdateCycle(idCycle: number, updateCycle: Cycles) {
        let cycle: Cycles = {
            id_cycle: 0,
            time_start_menstruation: "",
            time_end_menstruation: "",
            time_start_menstruation_edited: "",
            time_end_menstruation_edited: "",
            duration_cycle: 0,
            id_user: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.patch<Cycles>(BASE_URL + `/cycles/${idCycle}`, JSON.stringify(updateCycle), config)
            cycle = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {cycle, error}
    }

    public async DeleteCycle(idCycle: number) {
        let cycle: Cycles = {
            id_cycle: 0,
            time_start_menstruation: "",
            time_end_menstruation: "",
            time_start_menstruation_edited: "",
            time_end_menstruation_edited: "",
            duration_cycle: 0,
            id_user: 0,
            time_created: "",
            status: 0,
        }
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.delete<Cycles>(BASE_URL + `/cycles/${idCycle}`, config)
            cycle = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {cycle, error}
    }

    public async GetAllCycles() {
        let cycles: Cycles[] = []
        let error = ""

        try {
            const config = {
                headers: {
                    Token: getToken()
                }
            }
            const response = await axios.get<Cycles[]>(BASE_URL + `/cycles`, config)
            cycles = response.data
        } catch (e: unknown) {
            const err = e as AxiosError
            error = err.message
        }

        return {cycles, error}
    }
}