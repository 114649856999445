import {CreateUserRequest, Users} from "../models/Users";
import React, {useState} from "react";

interface ModalUserComponentProps {
    userRead: Users
    onClickSave: (newUser: CreateUserRequest) => void
    onClickExit: () => void
    isLoading: boolean
    textError: string
    isOwner: boolean
}

export function ModalUserComponent({userRead, onClickSave, onClickExit, isLoading, textError, isOwner}: ModalUserComponentProps) {
    const [nickname, setNickname] = useState(userRead.nickname)
    const [nicknameError, setNicknameError] = useState("")
    const [password, setPassword] = useState(userRead.password)
    const [passwordError, setPasswordError] = useState("")
    const [name, setName] = useState(userRead.name)
    const [nameError, setNameError] = useState("")
    const [cycleDuration, setCycleDuration] = useState(userRead.duration_cycle)
    const [cycleDurationError, setCycleDurationError] = useState("")
    const [menstruationDuration, setMenstruationDuration] = useState(userRead.duration_menstruation)
    const [menstruationDurationError, setMenstruationDurationError] = useState("")

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault()

        setNicknameError("")
        setPasswordError("")
        setNameError("")
        setCycleDurationError("")
        setMenstruationDurationError("")

        if (nickname.trim().length === 0) {
            setNicknameError("Пусто!")
            return
        }
        if (password.trim().length === 0) {
            setPasswordError("Пусто!")
            return
        }
        if (name.trim().length === 0) {
            setNameError("Пусто!")
            return
        }
        if (cycleDuration === 0) {
            setCycleDurationError("Пусто!")
            return
        }
        if (menstruationDuration === 0) {
            setMenstruationDurationError("Пусто!")
            return
        }

        onClickSave({
            nickname: nickname,
            password: password,
            name: name,
            duration_cycle: cycleDuration,
            duration_menstruation: menstruationDuration
        })
    }

    const changeNicknameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value)
    }
    const changePasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }
    const changeNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }
    const changeCycleDurationHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCycleDuration(+`${event.target.value}`)
    }
    const changeMenstruationDurationHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMenstruationDuration(+`${event.target.value}`)
    }

    let textButton = "Сохранить"
    if (isLoading) {
        textButton = "Сохраняем..."
    } else if (textError) {
        textButton = "Ошибка! Еще раз"
    }

    return (
        <form
            className="modal-pop-form"
            onSubmit={submitHandler}
        >
            {!nicknameError && <span className="modal-pop-label">ID</span>}
            <input
                className="modal-pop-input-readonly"
                type="text"
                id="idUser"
                name="idUser"
                value={userRead.id_user}
                readOnly
            />

            {isOwner && nicknameError && <span className="modal-pop-label modal-pop-error-color">Никнейм ({nicknameError})</span>}
            {isOwner && !nicknameError && <span className="modal-pop-label">Никнейм</span>}
            {isOwner &&
                <input
                    className="modal-pop-input"
                    type="text"
                    id="nickname"
                    name="nickname"
                    placeholder="nickname"
                    value={nickname}
                    onChange={changeNicknameHandler}
                />
            }

            {isOwner && passwordError && <span className="modal-pop-label modal-pop-error-color">Пароль ({passwordError})</span>}
            {isOwner && !passwordError && <span className="modal-pop-label">Пароль</span>}
            {isOwner &&
                <input
                    className="modal-pop-input"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="123456"
                    value={password}
                    onChange={changePasswordHandler}
                />
            }

            {nameError && <span className="modal-pop-label modal-pop-error-color">Имя ({nameError})</span>}
            {!nameError && <span className="modal-pop-label">Имя</span>}
            {isOwner &&
                <input
                    className="modal-pop-input"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Анна"
                    value={name}
                    onChange={changeNameHandler}
                />
            }
            {!isOwner &&
                <input
                    className="modal-pop-input-readonly"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Анна"
                    value={name}
                    readOnly
                />
            }

            {cycleDurationError && <span className="modal-pop-label modal-pop-error-color">Длительность цикла ({cycleDurationError})</span>}
            {!cycleDurationError && <span className="modal-pop-label">Длительность цикла</span>}
            {isOwner &&
                < input
                    className="modal-pop-input"
                    type="number"
                    id="cycleDuration"
                    name="cycleDuration"
                    min="1"
                    max="60"
                    value={cycleDuration}
                    onChange={changeCycleDurationHandler}
                />
            }
            {!isOwner &&
                < input
                    className="modal-pop-input-readonly"
                    type="text"
                    id="cycleDuration"
                    name="cycleDuration"
                    min="1"
                    max="60"
                    value={cycleDuration}
                    readOnly
                />
            }

            {menstruationDurationError && <span className="modal-pop-label modal-pop-error-color">Длительность менструации ({menstruationDurationError})</span>}
            {!menstruationDurationError && <span className="modal-pop-label">Длительность менструации</span>}
            {isOwner &&
                < input
                className="modal-pop-input"
                type="number"
                id="menstruationDuration"
                name="menstruationDuration"
                min="1"
                max="20"
                value={menstruationDuration}
                onChange={changeMenstruationDurationHandler}
                />
            }
            {!isOwner &&
                < input
                    className="modal-pop-input-readonly"
                    type="text"
                    id="menstruationDuration"
                    name="menstruationDuration"
                    min="1"
                    max="20"
                    value={menstruationDuration}
                    readOnly
                />
            }


            {(nicknameError || passwordError || nameError || cycleDurationError || menstruationDurationError) && <span className="modal-pop-label modal-pop-error-color">Поправьте поля</span>}

            {isOwner && isLoading && <button type="submit" className="modal-pop-button-animation">{textButton}</button>}
            {isOwner && !isLoading && <button type="submit" className="modal-pop-button">{textButton}</button>}

            {isOwner &&
                <>
                    <div style={{height: "10px"}}> </div>
                    <span className="modal-pop-button" onClick={onClickExit}>Выйти из аккаунта</span>
                </>
            }
        </form>
    )
}