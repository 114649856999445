import {MyTime} from "./MyTime";
import {getDurationCycle, getDurationMenstruation} from "./Cookie";
import {Cycles} from "../models/Cycles";

export function getCountDaysBeforeRedDays(lastCycle: Cycles) {
    let countDaysBeforeCycle = 0
    let timeStartMenstruationLastCycle = lastCycle.time_start_menstruation
    let splittedTimeStartMenstruationLastCycle = timeStartMenstruationLastCycle.split("-");

    if (splittedTimeStartMenstruationLastCycle.length > 2) {
        splittedTimeStartMenstruationLastCycle[2] = splittedTimeStartMenstruationLastCycle[2].substr(0, 2)
        let timeCycle = MyTime.shared.getTimeBy(+`${splittedTimeStartMenstruationLastCycle[0]}`, +`${splittedTimeStartMenstruationLastCycle[1]}` - 1, +`${splittedTimeStartMenstruationLastCycle[2]}`)
        let timeNow = MyTime.shared.getTimeNow()

        let profileDurationCycle = +`${getDurationCycle()}`
        let profileDurationMenstruation = +`${getDurationMenstruation()}`
        let countDaysPassed = MyTime.shared.getCountDaysBetweenTwoTime(timeCycle, timeNow)
        countDaysBeforeCycle = profileDurationCycle - (countDaysPassed % profileDurationCycle)

        if (profileDurationCycle - countDaysBeforeCycle < profileDurationMenstruation) {
            countDaysBeforeCycle = 0
        }
    }

    return countDaysBeforeCycle
}

export function getProbability(lastCycle: Cycles) {
    let probability = "Высокая"
    let countDaysBeforeCycle = 0
    let timeStartMenstruationLastCycle = lastCycle.time_start_menstruation
    let splittedTimeStartMenstruationLastCycle = timeStartMenstruationLastCycle.split("-");

    if (splittedTimeStartMenstruationLastCycle.length > 2) {
        splittedTimeStartMenstruationLastCycle[2] = splittedTimeStartMenstruationLastCycle[2].substr(0, 2)
        let timeCycle = MyTime.shared.getTimeBy(+`${splittedTimeStartMenstruationLastCycle[0]}`, +`${splittedTimeStartMenstruationLastCycle[1]}` - 1, +`${splittedTimeStartMenstruationLastCycle[2]}`)
        let timeNow = MyTime.shared.getTimeNow()

        let profileDurationCycle = +`${getDurationCycle()}`
        let countDaysPassed = MyTime.shared.getCountDaysBetweenTwoTime(timeCycle, timeNow)
        countDaysBeforeCycle = profileDurationCycle - (countDaysPassed % profileDurationCycle)

        if ((profileDurationCycle / 2) - 3 <= (profileDurationCycle - countDaysBeforeCycle) && (profileDurationCycle - countDaysBeforeCycle) <= (profileDurationCycle / 2) + 2) {
            probability = "Высокая"
        } else if ((profileDurationCycle / 2) - 6 <= (profileDurationCycle - countDaysBeforeCycle) && (profileDurationCycle - countDaysBeforeCycle) <= (profileDurationCycle / 2) + 5) {
            probability = "Средняя"
        } else {
            probability = "Низкая"
        }
    }

    return probability
}